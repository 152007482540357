import { useEffect, useState, useRef, useContext, useCallback } from 'react'
import { Alert, CustomTooltip, FileUploadDnD, Modal } from '../ui_new'
// import PdfPreview from '../UI/PdfPreview'
import byteConverter from '../../helpers/byteConverter'
import { NotificationContext, LoaderContext } from '../../context'
import { getFileData } from '../../utils'
import { Checkbox } from '../ui_new'
import { CheckCircle, Close, Eye, Weight } from '../../assets/icons'
import moment from 'moment'
import { Compress, Delete, Edit, Settings } from '@mui/icons-material'
import EditAttachmentModal from './EditAttachmentModal'
import { useAttachmentActions } from '../../hooks'

const AttachmentsVariableModal = ({ onClose, variable, attachments = [], onSave }) => {
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { previewAttachment, compressAttachment } = useAttachmentActions()

  const [variableAttachments, setVariableAttachments] = useState([])

  // const [previewModal, setPreviewModal] = useState({
  //   open: false,
  //   data: null,
  //   filename: ""
  // })
  const [editModal, setEditModal] = useState({
    open: false,
    attachment: null
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    attachment: null
  })

  useEffect(() => {
    const filteredAttachments = []
    for (let i = 0; i < attachments.length; i++) {
      const att = attachments[i]
      if (att.variable === variable.variable) {
        filteredAttachments.push({ ...att })
      }
    }
    setVariableAttachments(filteredAttachments)
  }, [attachments, variable])

  const handleSave = () => {
    onSave(variableAttachments)
  }

  // On file change
  const handleFileChange = async (e) => {
    const files = e.target.files
    addAttachmentsHelper(files)
  }

  // Copied from SingleDocumentAttachmensUpload.js
  // Upload files helper
  const allowedTypes = ['application/pdf']
  const addAttachmentsHelper = async (files) => {
    if (!files) return

    const promises = []
    let errors = []
    // const attNames = attachments.map(a => a.name)

    const filesArr = Array.from(files)
    for (let i = 0; i < filesArr.length; i++) {
      const file = filesArr[i]
      let components = file.name.split('.')
      components.splice(components.length - 1, 1)
      const name = components.join('.')

      if (!allowedTypes.includes(file.type)) {
        errors.push(`Type de fichier non valide pour la pièce jointe "${name}"`)
        continue
      }

      // todo check when submitting, user can compress before uploading
      // if (file.size > 50 * 1024 * 1024) {
      //   errors.push(`Le poids du fichier pour "${name}" est supérieur au poids autorisé (50MB)`)
      //   continue
      // }

      promises.push(getFileData(file))
    }

    if (errors.length > 0) {
      setNotification({ msg: errors.join('.'), type: 'danger' })
    }

    const attachmentsData = await Promise.all(promises)
    console.log(attachmentsData)
    setVariableAttachments((prev) => [
      ...prev,
      ...attachmentsData.map((att) => ({ ...att, created: Date.now(), variable: variable.variable }))
    ])
  }

  const handlePreview = (attachment) => {
    previewAttachment(attachment)
  }

  const handleCompress = async (attachment, attachmentIndex) => {
    setShowGlobalResponseLoader(true)
    try {
      const originalSize = attachment.size
      const compressionResponse = await compressAttachment(attachment)
      console.log("Compression response", compressionResponse)
      if (compressionResponse.error) {
        console.error(compressionResponse.error)
        return setNotification({ msg: compressionResponse.error || 'Une erreur est survenue lors de la compression du fichier', type: 'danger' })
      }
      const compressedAttachment = compressionResponse.attachment
      const newSize = compressionResponse.newSize
      const updatedAttachments = [...variableAttachments]
      updatedAttachments[attachmentIndex] = compressedAttachment
      // const updatedAttachments = variableAttachments.map(att => att.id === compressedAttachment.id ? compressedAttachment : att)
      setNotification({ msg: `Le fichier a été compressé avec succès. La taille du fichier a été réduite de ${byteConverter(originalSize - newSize)}`, type: 'success' })
      setVariableAttachments(updatedAttachments)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  const promptEdit = (attachment, attachmentIndex) => {
    setEditModal({
      open: true,
      attachment,
      attachmentIndex
    })
  }

  const handleEdit = (attachmentName) => {
    setVariableAttachments((prev) => {
      const updatedAttachments = [...prev]
      updatedAttachments[editModal.attachmentIndex].name = attachmentName
      return updatedAttachments
    })
    handleCloseEditModal()
  }

  const promptDelete = (attachment, attachmentIndex) => {
    setDeleteModal({
      open: true,
      attachment,
      attachmentIndex,
    })
  }

  const handleDelete = () => {
    setVariableAttachments((prev) => {
      const updatedAttachments = [...prev]
      updatedAttachments[deleteModal.attachmentIndex].deleted = true
      return updatedAttachments
    })
    handleCloseDeleteModal()
  }

  const handleCloseEditModal = () => {
    setEditModal({
      open: false,
      attachment: null,
      attachmentIndex: null
    })
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal({
      open: false,
      attachment: null,
      attachmentIndex: null
    })
  }

  return (
    <>
      <Modal onClose={onClose}>
        <div>
          <div className="folder-modal-v2__heading">
            <div className="signature-heading">
              <div>
                <h1>
                  Sélectionner des fichiers
                </h1>
                <h2>
                  {variable.display_name || variable.variable}
                </h2>
              </div>
            </div>
            <div className="buttons-row">
              <button className="btn btn--transparent" onClick={onClose}>Annuler</button>
              <button
                className='btn-separator'
              >
              </button>
              <button className="button action-btn" onClick={handleSave}>
                <span>
                  <CheckCircle />
                  Enregistrer
                </span>
              </button>
            </div>
          </div>
          <div className="folder-modal-v2__content">
            {variableAttachments.filter(a => !a.deleted).length === 0 && <span className="no-files-added">Aucun fichier ajouté.</span>}
            {variableAttachments.filter(a => !a.deleted).length > 0 && <div className="overflow-scroll">
              <div className="table-responsive">
                <table className="mb-6">
                  <thead>
                    <tr>
                      <th className='' width="calc(100% - 400px)">Nom</th>
                      <th className='' width="120px">Date</th>
                      <th className='' width="120px">Poids</th>
                      <th className='' width="160px">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variableAttachments.map((attachment, attachmentIndex) =>
                      !attachment.deleted &&
                      <tr key={`attachment-${attachmentIndex}`}>
                        <td className=" flex items-center">
                          <p className='line-clamp-2 !font-semibold'>
                            {attachment.name}
                          </p>
                        </td>
                        <td className="">
                          <p className='!font-semibold'>
                            {attachment.created ? moment(attachment.created).format('DD/MM/YYYY') : ''}
                          </p>
                        </td>
                        <td className="">
                          <p className='!font-semibold'>
                            {byteConverter(attachment.size)}
                          </p>
                        </td>
                        <td className="flex row align-center gap-2">

                          <CustomTooltip content="Aperçu">
                            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => handlePreview(attachment)}>
                              <Eye />
                            </button>
                          </CustomTooltip>
                          <CustomTooltip content="Compresser le document">
                            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => handleCompress(attachment, attachmentIndex)}>
                              <Compress />
                            </button>
                          </CustomTooltip>
                          <CustomTooltip content="Renommer">
                            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => promptEdit(attachment, attachmentIndex)}>
                              <Edit fontSize='small' />
                            </button>
                          </CustomTooltip>
                          <CustomTooltip content="Supprimer">
                            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => promptDelete(attachment, attachmentIndex)}>
                              <Delete fontSize='small' />
                            </button>
                          </CustomTooltip>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>}

            <div className=''>
              <FileUploadDnD
                label="Glisser-déposer un document"
                buttonPosition="inside-right"
                btnText="Sélectionner un document"
                className="mt-20 mb-20 w-full"
                height="sm"
                onChange={(files) => handleFileChange({ target: { files } })}
                accept='application/pdf'
                maxSize={50 * 1024 * 1024}
                multiple={true}
              />
            </div>
          </div>

        </div>
      </Modal>
      {editModal.open && (
        <EditAttachmentModal
          onClose={handleCloseEditModal}
          attachment={editModal.attachment}
          onEdit={handleEdit}
        />
      )}

      {deleteModal.open && (
        <Alert
          onClose={handleCloseDeleteModal}
          text={`Êtes-vous sûr de vouloir supprimer ce document ?`}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
    </>
  )
}

export default AttachmentsVariableModal