import moment from 'moment'
import { useState, useContext, useEffect } from 'react';
import { Close, FileAlt, Paperclip, ChevronLeft, Pen, Draft } from '../../assets/icons'
import { Select, Option, CustomTooltip } from '../ui_new'
import { STATUS_LABELS_SHORT } from '../../constants'
import { SingleDocumentSummary } from './'
import { useSingleDocumentActions } from '../../hooks'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { Search } from '../../assets/icons';
import { CalendarToday, Clear, ClearAll, AttachFile } from '@material-ui/icons';
import { DocumentsContext } from '../../context';
import { ArrowBack, CalendarTodayOutlined, Create } from '@mui/icons-material';

const SingleDocumentSidebar = ({
    singleDoc,
    currentDocVersionTime,
    documentValues,
    onVariableItemClick,
    scrollingToItem,
    onSectionClick,
    templateData,
    tabActive,
    onNameChange,
    view,
    onSetView,
    numOfAttachments,
    attachments,
    status,
    onSetStatus,
    progress,
    templateMetaSections,
    summarySections
}) => {
    const { extractVariableTypesFromSections } = useSingleDocumentActions()
    const [search, setSearch] = useState({
        state: 'not-started', // or started
        value: ''
    });
    const [searchResults, setSearchResults] = useState('');
    const [activeResult, setActiveResult] = useState(0);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [showInformationAccordion, setShowInformationAccordion] = useState(false);
    const [showSearchAccordion, setShowSearchAccordion] = useState(false);
    const {
        docSearch,
        setDocSearch,
    } = useContext(DocumentsContext);
    useEffect(() => {
        doSearch()
    }, [docSearch])
    // On status change
    const handleStatusChange = (e) => {
        const value = e;
        onSetStatus(value)
    }
    const { isMobile } = useWindowDimensions()

    const onPressEnterOnSearchInput = (event) => {
        if (event.keyCode === 13) {
            doSearch()
        }
    }

    const doSearch = () => {
        // Remove previous highlights
        const previousHighlights = document.querySelectorAll('mark');
        previousHighlights.forEach(highlight => {
            const parent = highlight.parentNode;
            parent.replaceChild(document.createTextNode(highlight.textContent), highlight);
            parent.normalize(); // Merge adjacent text nodes
        });
        // reset data
        // setSearch(prev => ({ state: 'not-started', value: '' }));
        setSearchResults([]);
        setActiveResult(0);

        if (docSearch.length === 0) {
            return;
        }
        // Get all elements that contain text nodes
        const elements = document.querySelectorAll(".document-main__body :not(script):not(style)");

        // Variable to track if scroll is done
        let scrollDone = false;
        setSearch(prev => ({ ...prev, state: 'started' }));
        // Iterate through each element
        let total = 0;
        elements.forEach(element => {
            // Iterate through each child node of the element
            Array.from(element.childNodes).forEach(node => {
                // Check if the node is a text node and contains the desired text
                if (node.nodeType === 3 && node.textContent?.toLowerCase().includes(docSearch?.toLowerCase())) {
                    const wrapper = document.createElement('span');
                    wrapper.innerHTML = node.textContent.replace(new RegExp(`(${docSearch})`, 'gi'), '<mark>$1</mark>');
                    element.replaceChild(wrapper, node);
                    setSearchResults(prev => [...prev, element]);
                    total += element.querySelectorAll('mark').length;
                    // Scroll to the first occurrence of the text
                    if (!scrollDone) {
                        wrapper.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        scrollDone = true;
                    }
                }
            });
        });
        setResultsTotal(total);
    }
    const prevResult = () => {
        if (activeResult >= 0 && searchResults[activeResult - 1]) {
            searchResults[activeResult - 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setActiveResult(prev => prev - 1);
        }
    }
    const nextResult = () => {
        if (activeResult < searchResults.length && searchResults[activeResult + 1]) {
            searchResults[activeResult + 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setActiveResult(prev => prev + 1);
        }
    }

    const clearSearch = () => {
        // Remove previous highlights
        const previousHighlights = document.querySelectorAll('mark');
        previousHighlights.forEach(highlight => {
            const parent = highlight.parentNode;
            parent.replaceChild(document.createTextNode(highlight.textContent), highlight);
            parent.normalize(); // Merge adjacent text nodes
        });
        setSearch(prev => ({ ...prev, state: 'not-started', value: '' }));
        setSearchResults([]);
    }

    const gotoAttachments = () => {
        window.history.pushState(null, null, window.location.pathname + '?attachments');
        onSetView('attachments');
    }
    
    const gotoDocument = () => {
        const url = new URL(window.location);
        url.searchParams.delete('attachments');
        window.history.pushState(null, null, url.toString());
        onSetView('variables');
    }

    return (
        <div
            className={`single-document__left single-document-tab`}
        >
            <section className="head">
                <h1>
                    <span>Rédaction d’un document</span>
                </h1>
            </section>
            <div className='accordion-parent'>
                <div className={`accordion-heading ${showInformationAccordion && 'active'}`} onClick={() => setShowInformationAccordion(!showInformationAccordion)}>
                    <span>Informations du document</span>
                    <ChevronLeft />
                </div>
                <section className={`${showInformationAccordion && 'active'} doc-info mb-6`}>
                    {view === 'variables' &&
                        <>

                            <label className="label-title color-indigo mb-1 pl-2">Titre du document</label>
                            <div className="title-input-wrapper">
                                <h2
                                    contentEditable={true}
                                    suppressContentEditableWarning={true}
                                    onInput={onNameChange}
                                >
                                    {singleDoc.name}
                                </h2>
                                <span>
                                    <Create fontSize='small' />
                                </span>
                            </div>
                            <div className='px-2'>
                                <label className="label-title mb-1 pl-1">
                                    <span className='status-dot'></span>
                                    Statut
                                </label>
                                <div className="status-row gap-4">
                                    <Select
                                        onChange={handleStatusChange}
                                        name="status"
                                        selected={status}
                                        className="!h-8"
                                    >
                                        {Object.entries(STATUS_LABELS_SHORT).map(
                                            ([stKey, stValue], idx) => (
                                                <Option value={stKey} key={`status-option-${idx}`}>
                                                    <span className={`status-circle ${stKey === 'ready' && 'green'}`}></span>
                                                    {stValue}
                                                </Option>
                                            )
                                        )}
                                    </Select>

                                    <p className={`doc-progress ${progress === 1 ? 'green' : ''} ${progress === 0 ? 'red' : ''}`}><span>{`${(progress * 100).toFixed(0)}%`}</span></p>
                                </div>
                                {/* <p className="doc-status"><span className={`${singleDoc.status}`}></span> {STATUS_LABELS_SHORT[singleDoc.status]}</p> */}
                                {view === 'variables' && <div className="doc-version">
                                    <div className="flex gap-2 align-center mb-1">
                                        <CalendarTodayOutlined />
                                        <label className="label-title">
                                            Version du document
                                        </label>
                                    </div>
                                    <p>
                                        {currentDocVersionTime &&
                                            currentDocVersionTime.time &&
                                            moment(currentDocVersionTime.time).format(
                                                'DD/MM/YYYY HH[H]mm'
                                            )}
                                    </p>
                                </div>}
                            </div>
                        </>
                    }

                    {view === 'variables' && <div className="doc-attachment-parent">
                        <div className="doc-attachment" >
                            <AttachFile />
                            <span className='leading-none'>Annexe(s) du document </span>
                            <span className="count">{numOfAttachments}</span>
                        </div>
                        <ul className="attachment-list">
                            {attachments?.map((attachment, idx) =>
                                <li key={`attachment-list-item-${idx}`}>
                                    <Draft /> <span className='file-name'>{String(attachment.name)}</span>
                                </li>
                            )}
                        </ul>
                        <button className="button button--outline-primary-light rounded-sm btn-text w-full modify-btn" onClick={gotoAttachments}>
                            <Pen />
                            <span className="whitespace-nowrap">Modifier les annexes </span>
                        </button>
                    </div>}
                    {view === 'attachments' && <button className="button button--with-icon button--primary-light btn--medium" onClick={gotoDocument}><ArrowBack fontSize='small' /> Retour au document</button>}
                </section>
            </div>

            {/* <section className="search">
                <div className="search-bar">
                    <div className="search-bar__body">
                        <div className="search-bar__body_icon cursor-pointer" onClick={doSearch}>
                            <Search />
                        </div>
                        <input
                            type="text"
                            placeholder="Rechercher…"
                            value={search.value}
                            onChange={(e) => setSearch(prev => ({ ...prev, value: e.target.value }))}
                            onKeyDown={onPressEnterOnSearchInput}
                        />
                        {search.state !== "not-started" && (
                            <div
                                className="search-bar__body_icon search-bar__body_icon_end icon-btn icon-btn--sm icon-btn--transparent cursor-pointer"
                                onClick={clearSearch}
                            >
                                <Close />
                            </div>
                        )}
                    </div>
                    {search.state === 'started' && <>
                        <div className='flex align-center mt-3'>
                            <p className='search-results'>{`${resultsTotal} résultat${resultsTotal > 1 ? 's' : ''} trouvé${resultsTotal > 1 ? 's' : ''}`}</p>
                            <button className='btn btn--transparent prev' onClick={prevResult}><ChevronLeft /></button>
                            <button className='btn btn--transparent next' onClick={nextResult}><ChevronLeft /></button>
                        </div>
                    </>}
                </div>
            </section> */}
            {view === 'variables' && <div className='accordion-parent'>
                <div className={`accordion-heading ${showSearchAccordion && 'active'}`} onClick={() => setShowSearchAccordion(!showSearchAccordion)}>
                    <span>Sommaire</span>
                    <ChevronLeft />
                </div>

                <section className={`${showSearchAccordion && 'active'} summary`}>
                    <h3>Sommaire</h3>
                    {/* TODO: fix summary for nested repeatables and re-enable */}
                    <SingleDocumentSummary
                        documentValues={documentValues}
                        variableTypes={extractVariableTypesFromSections()}
                        onItemClick={onVariableItemClick}
                        scrollingToItem={scrollingToItem}
                        onSectionClick={onSectionClick}
                        templateData={templateData}
                        templateMetaSections={templateMetaSections}
                        summarySections={summarySections}
                    />
                </section>
            </div>}
        </div>
    )
}

export default SingleDocumentSidebar
