import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import config from '../config.json';
import {
  JURISUR_SORT_LS,
  JURISUR_PAGINATION_LS,
  AR24_SENDER_STATUS_LABEL,
  EMPTY_LINES,
  DOC_CONTACTS_KEYS,
  DOC_CONTACT_PREFIXES,
  CONTACT_ID_VARIABLES,
} from '../constants';

const sortedArrayFromObject = (obj, sorter) => {
  let arr = [];
  for (let key in obj) {
    let item = obj[key];
    item.id = key;
    arr.push(item);
  }
  if (sorter) {
    arr.sort(sorter);
  }
  return arr;
};

const sorterWithPathAndOrder = (path, order) => (a, b) => {
  const splitted = path.split('.');
  let valA = a[splitted[0]];
  let valB = b[splitted[0]];
  if (splitted.length === 1) {
    if (typeof valA === 'string' && typeof valB === 'string') {
      if (valA.toLowerCase() < valB.toLowerCase())
        return -1 * (order === 'desc' ? -1 : 1);
      else if (valA.toLowerCase() > valB.toLowerCase())
        return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    } else {
      if (valA < valB) return -1 * (order === 'desc' ? -1 : 1);
      else if (valA > valB) return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    }
  } else {
    if (splitted[1].includes('__arr')) {
      const newPath = splitted[1].split('__arr');
      if (valA[0]?.[newPath[0]] < valB[0]?.[newPath[0]])
        return -1 * (order === 'desc' ? -1 : 1);
      else if (valA[0]?.[newPath[0]] > valB[0]?.[newPath[0]])
        return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    } else {
      if (valA?.[splitted[1]] < valB?.[splitted[1]])
        return -1 * (order === 'desc' ? -1 : 1);
      else if (valA?.[splitted[1]] > valB?.[splitted[1]])
        return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    }
  }
};

const sortArrayOfObjects = (arr, value, order) => {
  const splitted = value.split('.');
  if (value === 'recipients.email__arr') {
    return arr.sort((a, b) => {
      if (
        a.recipients[0].email.toLowerCase() <
        b.recipients[0].email.toLowerCase()
      ) {
        return -1 * (order === 'desc' ? -1 : 1);
      } else if (
        a.recipients[0].email.toLowerCase() >
        b.recipients[0].email.toLowerCase()
      ) {
        return 1 * (order === 'desc' ? -1 : 1);
      } else return 0;
    });
  }
  let sortedArray = arr.sort((a, b) => {
    let valA =
      splitted.length === 1 ? a[splitted[0]] : a[splitted[0]]?.[splitted[1]];
    let valB =
      splitted.length === 1 ? b[splitted[0]] : b[splitted[0]]?.[splitted[1]];

    // Handle undefined values
    if (valA === undefined) return 1 * (order === 'desc' ? -1 : 1);
    if (valB === undefined) return -1 * (order === 'desc' ? -1 : 1);

    if (typeof valA === 'string' && typeof valB === 'string') {
      valA = valA.toLowerCase();
      valB = valB.toLowerCase();
    }
    if (valA < valB) return -1 * (order === 'desc' ? -1 : 1);
    else if (valA > valB) return 1 * (order === 'desc' ? -1 : 1);
    else return 0;
  });
  if (value === 'progress') {
    sortedArray = sortedArray.sort((a, b) => {
      if (order === 'asc') {
        // Move "completed" to the end
        if (a.status === 'ready' && b.status !== 'ready') return 1;
        if (a.status !== 'ready' && b.status === 'ready') return -1;
        return 0; // Preserve the original order of other statuses
      } else if (order === 'desc') {
        // Move "ready" to the beginning
        if (a.status === 'ready' && b.status !== 'ready') return -1;
        if (a.status !== 'ready' && b.status === 'ready') return 1;
        return 0; // Preserve the original order of other statuses
      }
      return 0; // In case the order parameter is not 'asc' or 'desc'
    });
    console.log(sortedArray)
  }
  if(value === 'hidden') {
    sortedArray = sortedArray.sort((a, b) => {
      if (order === 'asc') {
        if (a.hidden && !b.hidden) return 1;
        if (!a.hidden && b.hidden) return -1;
        return 0;
      } else if (order === 'desc') {
        if (a.hidden && !b.hidden) return -1;
        if (!a.hidden && b.hidden) return 1;
        return 0;
      }
      return 0;
    });
  }
  return sortedArray
};

const getCreatedAtFromDocuments = (documents) => {
  const arr = [];
  for (let key in documents) {
    const value = documents[key].meta.created;
    const label = moment(value).format('MMMM YYYY');
    const start = moment(value).startOf('month').valueOf();
    const end = moment(value).endOf('month').valueOf();
    arr.push({ value, label, start, end });
  }
  // return only unique objects
  return arr.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i);
};

// const dateValueFormat = 'YYYY-MM-DD[T]HH:mmZZ'
const dateValueFormat = 'DD/MM/YYYY';

const urlSuffixForEnvironment = (environment) => {
  switch (environment) {
    case 'development':
      return '_dev';
    case 'staging':
      return '_stg';
    default:
      return '';
  }
};

const getAllParentFolders = (allFolders, folder, folders = []) => {
  if (!folder) {
    return folders;
  }
  const parentFolderId = folder.parentFolder;
  if (parentFolderId) {
    const pF = [...allFolders].find((f) => f.id === parentFolderId);
    return pF ? getAllParentFolders(allFolders, pF, [...folders, pF]) : [];
  } else {
    return folders;
  }
};

const folderHasSubfolders = (folders, folder) => {
  return !!folders.find((f) => f.parentFolder === folder.id);
};

const folderHasTemplates = (templates, folder) => {
  const arr = [];
  for (let key in templates) {
    arr.push({ ...templates[key], id: key });
  }
  return !!arr.find(
    (t) => !t.deleted && t.folderId && t.folderId.includes(folder.id)
  );
};

const getFirstLevelSubfolders = (folders, folder) => {
  return folder
    ? [...folders].filter((f) => f.parentFolder === folder.id)
    : [...folders].filter(
      (f) => f.parentFolder === null || f.parentFolder === undefined
    );
};

const isOverflown = (el) => {
  if (el) {
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = el;
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  } else {
    return false;
  }
};

const convertToTemplateObjWithUniqueVarIndexes = (obj) => {
  const copyOfTemplate = { ...obj };
  const tmplSections = copyOfTemplate?.sections ? [...copyOfTemplate.sections] : [];
  const updatedSections = tmplSections.map((s, i) => {
    const section = { ...s };
    if (section.variable) {
      section.idx = `${section.variable}-${uuidv4()}`;
      return section;
    } else if (section.variables) {
      section.variables = [...section.variables].map((v) => ({
        ...v,
        idx: `${v.variable}-${uuidv4()}`,
      }));
      return section;
    } else {
      return section;
    }
  });
  copyOfTemplate.sections = updatedSections;

  if (copyOfTemplate.footer) {
    const updatedFooter = { ...copyOfTemplate.footer };
    if (updatedFooter.variables) {
      updatedFooter.variables = [...updatedFooter.variables].map((v) => ({
        ...v,
        idx: `${v.variable}-${uuidv4()}`,
      }));
    }
    copyOfTemplate.footer = updatedFooter;
  }
  return copyOfTemplate;
};

// Read file
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

// Convert base64 to blob
const base64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// Blob to file
const blobToFile = (blob, name) => {
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return blob;
};

// 1. SH token, 2. CAI token
const signatureAvailable = (token = '') => {
  return true; // still using this function for now, in case they change their mind
  // return config.environment === 'development' ||
  //   config.environment === 'staging' ||
  //   token === 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZG1pbl9pZCI6Njk0Nzg2LCJzaXRlX2lkIjo2MDQ5MiwibWFudWZhY3R1cmVyX2lkIjoyOTAwNzB9.cz_z5yyBrYsxTtFfozKKjqbDbN8m4QLs0aqlAeSRjBc' ||
  //   token === 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZG1pbl9pZCI6NTMxMzg4LCJzaXRlX2lkIjozMzE2OSwibWFudWZhY3R1cmVyX2lkIjoxNzE5MTEsImN1c3RvbWVyX2lkIjpudWxsLCJwcm9kdWN0X2lkIjoiMjgyMzE4MTIifQ.qXaDkwQiMhxj7b8KixWI4kr4n3KID95CI4fUK8ldnTs'
};

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const availableOn = (arr) => {
  return arr && arr.includes(config.environment);
};

export const FEATURE = {
  AR24: 'ar24',
  STANDARD_TEMPLATES: 'standard-templates',
  CROSS_CATEGORY_TAG_MATCHING: 'cross-category-tag-matching',
  INTERAGENCY: 'interagency',
  FXO_CHATBOT: 'fxo-chatbot',
  COVER_PAGE_SETTINGS: 'cover-page-settings',
  DOCUMENT_CUSTOMIZATION: 'document-customization',
  EDITABLE_CONTENT_LITE: 'editable-content-lite',
  DEFAULT_ATTACHMENTS: 'default-attachments',
  REQUIRED_ATTACHMENTS: 'required-attachments',
}

const FEATURES_DEPLOYMENT_SCHEME = {
  [FEATURE.AR24]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.STANDARD_TEMPLATES]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.CROSS_CATEGORY_TAG_MATCHING]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.INTERAGENCY]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.FXO_CHATBOT]: {
    development: true,
    staging: false,
    production: false,
  },
  [FEATURE.COVER_PAGE_SETTINGS]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.EDITABLE_CONTENT_LITE]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.DEFAULT_ATTACHMENTS]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.DOCUMENT_CUSTOMIZATION]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.REQUIRED_ATTACHMENTS]: {
    development: true,
    staging: true,
    production: true,
  },
}

const FEATURE_PARTNER_CONDITIONS = {
  [FEATURE.FXO_CHATBOT]: ['jurisur'],
}

const isFeatureEnabled = (feature, siteConfigs = [], partner = 'jurisur', user) => {
  if (!FEATURES_DEPLOYMENT_SCHEME[feature]) {
    throw new Error(`Unknown feature ${feature}`)
  }
  if (feature === FEATURE.COVER_PAGE_SETTINGS) {
    return canUseCoverPageSettings(user, partner)
  }
  if(feature === FEATURE.DOCUMENT_CUSTOMIZATION) {
    const availablePartners = ['jurisur', 'kel']
    return availablePartners.includes(partner)
  }
  if (!Boolean(FEATURES_DEPLOYMENT_SCHEME[feature]) || !FEATURES_DEPLOYMENT_SCHEME[feature][config.environment]) {
    return false
  }
  if (feature === FEATURE.AR24) {
    const configLRE = siteConfigs.find(c => c.key === 'MODULE_LRE')
    if (configLRE && configLRE.value) {
      return true
    }
    return false
  }
  if (FEATURE_PARTNER_CONDITIONS[feature]) {
    return FEATURE_PARTNER_CONDITIONS[feature].includes(partner)
  }
  if (feature === FEATURE.EDITABLE_CONTENT_LITE) {
    return user.authorized_update_document
  }

  return true
}


const canUseTemplateCategories = (partner) => {
  const arr = [
    'jurisur',
    'squarehabitat',
    'cai',
    'mls1',
    'mls2',
    'mls3',
    'alphabet',
    'kel'
  ];
  return arr.includes(partner);
};

const isMlsPartner = (partner) => {
  if (!partner) return false;
  return partner.substring(0, 3) === 'mls';
};

const copyToClipboard = (text) => {
  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
};

const areSectionConditionsMet = (section, values) => {
  // return true
  // console.log("are section conditions met", section, values);
  // const { repeatableIndex } = section
  const { repetitionIndices, repeatableIndex } = section;

  // if(repeatableIds && repeatableIds.length > 0) {
  //   return true // TODO fix, workaround for nested repeatables update
  // }

  if (
    (!section.conditions || section.conditions?.length === 0) &&
    !section.condition
  ) {
    return true;
  } else if (section.conditions?.length > 0) {
    for (let i in section.conditions) {
      let cond = section.conditions[i];
      if (!isConditionMet(cond, values, repeatableIndex, repetitionIndices)) {
        return false;
      }
    }
    return true;
  } else {
    return isConditionMet(section.condition, values, repeatableIndex, repetitionIndices);
  }
};

const isConditionMet = (cond, values, repeatableIndex, repetitionIndices) => {
  // console.log('isConditionMet', cond, repeatableIndex)
  let targetValue = cond.value;
  let sourceValue
  if (cond.repeatableIds) {
    // let testSourceValue = duplicatableValuesArray(values, cond.repeatableIds, repetitionIndices)
    sourceValue = nestedValue(values, cond.variable, cond.repeatableIds, repetitionIndices)
    // console.log("is condition met", { sourceValue, targetValue, cond, repetitionIndices })
  } else if (cond.repeatableId) {
    sourceValue = values[cond.repeatableId]?.[repeatableIndex]?.[cond.variable] || null
  } else {
    sourceValue = values[cond.variable];
  }
  // console.log("is condition met", { sourceValue, targetValue, cond, repeatableIndex, repetitionIndices })

  if (typeof targetValue === 'number') {
    sourceValue = parseFloat(sourceValue);
  }
  if (cond.relation === 'EQ') {
    return sourceValue === targetValue;
  } else if (cond.relation === 'NE') {
    return sourceValue !== targetValue;
  } else if (cond.relation === 'GT') {
    return sourceValue > targetValue;
  } else if (cond.relation === 'LT') {
    return sourceValue < targetValue;
  }
}

const nestedValue = (values, variable, repSectionIds, repIndices) => {
  let vals = { ...values }
  let repeatableSectionIds = repSectionIds ? [...repSectionIds] : []
  let repetitionIndices = repIndices ? [...repIndices] : []
  while (repeatableSectionIds.length > 0) {
    let repId = repeatableSectionIds.shift()
    let repIndex = repetitionIndices.shift()
    vals = vals[repId]
    if (!vals) {
      return null
    }
    if (repIndex !== undefined) {
      vals = vals[repIndex]
    }
  }
  return vals?.[variable]
}

const EVENT_TYPES = {
  DOCUMENT_CREATE: 'document_create',
  DOCUMENT_DELETE: 'document_delete',
  DOCUMENT_DOWNLOAD: 'document_download',
  DOCUMENT_DOWNLOAD_PDF: 'document_download_pdf',
  DOCUMENT_DOWNLOAD_DOCX: 'document_download_docx',
  DOCUMENT_PREVIEW: 'document_preview',
  IMMOCLOUD_UPLOAD: 'immocloud_upload',
  DOCUMENT_SHARE: 'document_share',
};

const isCoverPageVariable = (v) => {
  if (!v || !v.includes) return false
  return v.includes('cover_page_');
};

const coverPageConfig = (useCustomCover, agency, user, documentName, hasCoverPage = true, partner) => {
  let coverPageVariables = {};
  let coverPageImages = {};
  let coverPageOptions = {};
  if (agency?.cover_use_logo) {
    if (agency?.cover_logo_input !== "agency" && agency?.logo) {
      coverPageImages.logo = agency.logo;
    } else if (agency?.cover_logo_input === "agency") {
      coverPageImages.logo = agencyLogoUrl(user.manufacturer);
    } else {
      coverPageImages.logo = '';
    }
  } else {
    coverPageImages.logo = '';
  }
  if (agency?.cover_image_input !== 'none') {
    if (agency?.cover && agency?.cover_image_input === 'custom') {
      coverPageImages.cover = agency?.cover;
    }
  } else {
    coverPageImages.cover = '';
  }
  if (agency?.cover_color) {
    coverPageOptions.cover_color = agency.cover_color;
    if (!coverPageOptions.font_config) {
      coverPageOptions.font_config = {}
    }
    if(isFeatureEnabled(FEATURE.DOCUMENT_CUSTOMIZATION, [], partner, user) && agency) {
      coverPageOptions.font_config = {
          heading_2: {
              color: agency.cover_color,
              borderColor: agency.cover_color,
          },
          heading_3: {
              color: "#ffffff",
              backgroundColor: agency.cover_color,
          },
          footer_title: {
              color: agency.cover_color,
          },
          cover_title: {
              color: agency.cover_color,
          }
      }
    }
  }
  if (agency?.cover_admin_info && agency?.cover_admin_photo && user.picture) {
    coverPageImages.cover_admin_photo = user.picture;
  } else {
    coverPageImages.cover_admin_photo = '';
  }
  coverPageVariables = matchCoverPageVariables(
    user,
    agency?.cover_agency_info,
    agency?.cover_admin_info
  );
  if (agency?.font_family) {
    if (!coverPageOptions.font_config) {
      coverPageOptions.font_config = {}
    }
    coverPageOptions.font_config.font_family = agency.font_family
  }
  if (!useCustomCover) {
    coverPageOptions.remove_cover_page = true;
    coverPageImages.cover_admin_photo = '';
    coverPageImages.logo = '';
  }
  coverPageVariables.cover_page_title = documentName;

  return {
    coverPageVariables,
    coverPageImages,
    coverPageOptions,
  };
};

const matchCoverPageVariables = (user, useAgency, useAdmin) => {
  const values = {};
  if (useAgency) {
    const agencyLines = [];
    agencyLines.push(manufacturerProperty(user?.manufacturer, 'name') || 'Immo Docs');
    agencyLines.push(
      manufacturerProperty(user?.manufacturer, 'address').trim()
    );
    agencyLines.push(
      `${manufacturerProperty(user?.manufacturer, 'zip')} ${manufacturerProperty(user?.manufacturer, 'city')}`.trim()
    );
    agencyLines.push(
      manufacturerProperty(user?.manufacturer, 'phone').trim()
    );
    agencyLines.push(manufacturerProperty(user?.manufacturer, 'email').trim());
    values.cover_page_agency = manufacturerProperty(user?.manufacturer, 'name') || 'Immo Docs'
    values.cover_page_address = manufacturerProperty(user?.manufacturer, 'address').trim()
    values.cover_page_cp = manufacturerProperty(user?.manufacturer, 'zip').trim()
    values.cover_page_city = manufacturerProperty(user?.manufacturer, 'city').trim()
    values.cover_page_phone = manufacturerProperty(user?.manufacturer, 'phone').trim()
    values.cover_page_email = manufacturerProperty(user?.manufacturer, 'email').trim()
    let linesOffset = 0;
    for (let i = 0; i < agencyLines.length; i++) {
      if (agencyLines[i]) {
        values[`cover_page_line${i + 1 + linesOffset}`] = agencyLines[i];
      } else {
        linesOffset--;
      }
    }
  }
  if (useAdmin) {
    const adminLines = [];
    adminLines.push(`${user.firstname} ${user.lastname}`.trim());
    adminLines.push(`${user.email || ''}`.trim());
    adminLines.push(`${user.phone || ''} - ${user.mobile_phone || ''}`.trim());
    adminLines.push(
      `${user.fonctions && user.fonctions.length > 0
        ? user.fonctions[0].name
        : ''
        }`.trim()
    );
    adminLines.push(`${user.rsac || ''} - ${user.ville_rsac || ''}`.trim());
    adminLines.push(
      `${user.num_rcp_pro || ''} - ${user.organisme_rcp_pro || ''}`.trim()
    );
    values.cover_page_admin_name = `${user.firstname} ${user.lastname}`.trim()
    values.cover_page_admin_email = `${user.email || ''}`.trim()
    values.cover_page_admin_phone = `${user.mobile_phone || user.phone || ''}`.trim()
    values.cover_page_admin_role = `${user.fonctions && user.fonctions.length > 0 ? user.fonctions[0].name : ''}`.trim()
    let linesOffset = 6;
    for (let i = 0; i < adminLines.length; i++) {
      if (adminLines[i] !== '' && adminLines[i] !== '-') {
        values[`cover_page_line${i + 1 + linesOffset}`] = adminLines[i];
      } else {
        linesOffset--;
      }
    }
  }
  return values;
};

// Save sort to LS
const saveSortingToLS = (value, order, type) => {
  const sortLS = localStorage.getItem(JURISUR_SORT_LS);
  if (sortLS) {
    const sortingObj = JSON.parse(sortLS);
    let obj = { ...sortingObj };
    obj[type] = { value, order };
    localStorage.setItem(JURISUR_SORT_LS, JSON.stringify(obj));
  } else {
    let obj = {};
    obj[type] = { value, order };
    localStorage.setItem(JURISUR_SORT_LS, JSON.stringify(obj));
  }
};

// Get jurisur sort from LS
const getSorting = () => {
  const sortLS = localStorage.getItem(JURISUR_SORT_LS);
  return JSON.parse(sortLS);
};

// Save pagination data to LS
const savePaginationDataToLS = (numOfItemsToShow, currentPage, all, type) => {
  const paginationLS = localStorage.getItem(JURISUR_PAGINATION_LS);
  if (paginationLS) {
    const paginationObj = JSON.parse(paginationLS);
    let obj = { ...paginationObj };
    obj[type] = { items: numOfItemsToShow, current: currentPage, all };
    localStorage.setItem(JURISUR_PAGINATION_LS, JSON.stringify(obj));
  } else {
    let obj = {};
    obj[type] = { items: numOfItemsToShow, current: currentPage, all };
    localStorage.setItem(JURISUR_PAGINATION_LS, JSON.stringify(obj));
  }
};

// Get jurisur pagination data from LS
const getPaginationData = () => {
  const paginationLS = localStorage.getItem(JURISUR_PAGINATION_LS);
  return JSON.parse(paginationLS);
};

const formulasInSection = (s) => {
  let formulas = []
  if (s.type === 'text' || s.type.includes('heading')) {
    return findFormulasInSection(s)
  } else if (s.type === 'table') {
    for (let c of s.head) {
      formulas.push(...findFormulasInSection(c))
    }
    for (let c of s.row) {
      formulas.push(...findFormulasInSection(c))
    }
  } else if (s.type === 'static-table') {
    if (!s.rows) return []
    for (let row of s.rows) {
      if (!row.cells) continue
      for (let cell of row.cells) {
        if (!cell.sections) continue
        for (let cellSection of cell.sections) {
          formulas.push(...formulasInSection(cellSection))
        }
      }
    }
  }
  return formulas
}

const getTemplateFormulas = (templateObject) => {
  let formulas = []
  for (let s of (templateObject?.sections || [])) {
    formulas.push(...formulasInSection(s))
  }
  let uniqueFormulas = []
  formulas.forEach(f => {
    if (!uniqueFormulas.find(uf => uf.handle === f.handle)) {
      uniqueFormulas.push(f)
    }
  })
  return uniqueFormulas
}

const findFormulasInSection = (section) => {
  if (!section.content) {
    return []
  }
  let formulaRegex = /\{f\(([^}]*)\}/g
  // let fieldRegex = /\{d\.([^}]*)\}|\{f\(([^}]*)\}/g
  let matches = section.content.match(formulaRegex)
  let formulas = []
  if (matches?.length > 0) {
    matches.forEach(m => {
      formulas.push({ handle: m, repeatableId: section.repeatable_section_id })
    })
  }
  return formulas
}

const insertFormulaResults = (values, documentFormulas) => {
  let results = { ...values }
  for (let f of documentFormulas) {
    if (f.repeatableId) {
      if (!results[f.repeatableId]) {
        continue
      }
      for (let i in results[f.repeatableId]) {
        let { key, value } = formulaResult(values, f.handle, f.repeatableId, i)
        results[f.repeatableId][key] = value
      }
    } else {
      let { key, value } = formulaResult(values, f.handle)
      results[key] = value
    }
  }

  return results
}

const NUMBER_FORMATTERS = {
  "format('in_words')": (v) => {
    if (!v) return ''
    return numberToWords(v, 'fr')
  },
  "format('in_words_euro')": (v) => {
    if (!v) return ''
    return numberToWords(v, 'fr', 'euro')
  },
  "format('ht_5')": (v) => {
    if (!v) return ''
    const val = parseFloat(v) / 1.05
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ht_5_5')": (v) => {
    if (!v) return ''
    const val = parseFloat(v) / 1.055
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ht_20')": (v) => {
    if (!v) return ''
    const val = parseFloat(v) / 1.2
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ttc_5')": (v) => {
    if (!v) return ''
    const val = parseFloat(v) * 1.05
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ttc_5_5')": (v) => {
    if (!v) return ''
    const val = parseFloat(v) * 1.055
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ttc_20')": (v) => {
    if (!v) return ''
    const val = parseFloat(v) * 1.2
    return val.toFixed(nonZeroDecimalsCount(val))
  }
}

const nonZeroDecimalsCount = (v, maxDecimalsCount = 3) => {
  const str = `${parseFloat(v).toFixed(maxDecimalsCount)}`
  let decimalPart = str.split('.')[1]
  while (decimalPart && decimalPart[decimalPart.length - 1] === '0') {
    decimalPart = decimalPart.slice(0, -1)
  }
  if (decimalPart) {
    return decimalPart.length
  }
  return 0
}

const formulaResult = (values, handle, repId, repIndex) => {

  const cleanedHandle = handle
    .substring(1, handle.length - 1)
    .replace(/\./g, '');

  let formulaBody = handle.substring(3, handle.length - 1).split(':')[0]
  formulaBody = formulaBody.substring(0, formulaBody.length - 1)
  const formattersString = handle.substring(3, handle.length - 1).split(':').slice(1).join(':')
  const formatters = formattersString.split(':').filter(f => f !== '')

  let { result, valid } = evaluateFormula(values, formulaBody, repId, repIndex);

  if (formatters.length > 0) {
    let val = parseFloat(result)
    if (isNaN(val)) {
      return { key: cleanedHandle, value: 'Formule invalide' }
    }
    for (let i = 0; i < formatters.length; i++) {
      const f = formatters[i]
      if (NUMBER_FORMATTERS[f]) {
        val = NUMBER_FORMATTERS[f](val)
      }
    }
    result = val
  }
  if (!valid) {
    return { key: cleanedHandle, value: 'Formule invalide' }
  }
  return { key: cleanedHandle, value: result };
};

const functionWords = ['sum', 'inWords'];

const evaluateFormula = (values, formula, repId, repIndex) => {
  const parsed = parseFormula(formula);
  let functionIndex = -1;

  for (let i = 0; i < parsed.length; i++) {
    if (functionWords.includes(parsed[i])) {
      functionIndex = i;
      break;
    }
  }

  while (functionIndex !== -1) {
    let body = functionBody(parsed, functionIndex + 2);
    let functionName = parsed[functionIndex];
    let val = '';
    if (functionName === 'sum') {
      val = evaluateSum(values, body.join(''), repId, repIndex);
    } else if (functionName === 'inwords') {
      val = evaluateInWords(values, body.join(''), repId, repIndex);
    }
    parsed.splice(functionIndex, body.length + 3, val);
    functionIndex = -1;
    for (let i = 0; i < parsed.length; i++) {
      let component = parsed[i];
      if (functionWords.includes(component)) {
        functionIndex = i;
        break;
      }
    }
  }
  for (let i = 0; i < parsed.length; i++) {
    let dataValue = values[parsed[i]];
    if (dataValue) {
      parsed[i] = dataValue;
    }
  }

  let result;
  if (parsed.length === 1) {
    result = parsed[0];
  } else {
    result = evaluateExpression(parsed.join(' '));
  }

  return { result, valid: true };
};

const functionBody = (parsedFormula, startIndex) => {
  let openParentheses = 0;
  let body = [];
  for (let i = startIndex; i < parsedFormula.length; i++) {
    if (parsedFormula[i] === ')' && openParentheses === 0) {
      return body;
    } else if (parsedFormula[i] === ')') {
      openParentheses--;
    } else if (parsedFormula[i] === '(') {
      openParentheses++;
    }
    body.push(parsedFormula[i]);
  }
  return body;
};

const operators = ['+', '-', '*', '/'];
const parentheses = ['(', ')'];

const parseFormula = (formula) => {
  let currentComponent = '';
  let components = [];
  for (let i = 0; i < formula.length; i++) {
    let char = formula[i];
    if (operators.includes(char) || parentheses.includes(char)) {
      if (currentComponent) {
        components.push(currentComponent);
      }
      components.push(char);
      currentComponent = '';
      continue;
    }
    currentComponent += formula[i];
  }
  if (currentComponent) {
    components.push(currentComponent);
  }
  return components;
};

const evaluateExpression = (string) => {
  const parts = string.split(' ');
  const parsed = [];
  for (let p of parts) {
    let part = p.trim();
    if (part) {
      let number = parseFloat(part);
      if (!isNaN(number)) {
        parsed.push(number);
      } else {
        parsed.push(part);
      }
    }
  }

  while (parsed.includes('(') && parsed.includes(')')) {
    let block = [];
    let blockStart;
    let blockEnd;
    for (let i = 0; i < parsed.length; i++) {
      let p = parsed[i];
      if (p === ')') {
        blockEnd = i;
        break;
      }
      if (p === '(') {
        blockStart = i;
      }
    }
    block = parsed.slice(blockStart + 1, blockEnd);
    let result = evaluateExpression(block.join(' '));
    parsed.splice(blockStart, block.length + 2, result);
  }

  while (parsed.includes('*') || parsed.includes('/')) {
    let operandA;
    let operandB;
    let operator;
    let operationStart;
    for (let i = 0; i < parsed.length; i++) {
      let p = parsed[i];
      if (p === '*' || p === '/') {
        operandA = parsed[i - 1];
        operandB = parsed[i + 1];
        operator = p;
        operationStart = i - 1;
        break;
      }
    }
    let operandAValue = parseFloat(operandA);
    if(isNaN(operandAValue)) {
      operandAValue = 0
    }
    let operandBValue = parseFloat(operandB);
    if(isNaN(operandBValue)) {
      operandBValue = 0
    }
    let result =
      operator === '*' ? operandAValue * operandBValue : parseFloat(operandAValue / operandBValue);
    parsed.splice(operationStart, 3, result);
  }

  while (parsed.includes('+') || parsed.includes('-')) {
    let operandA;
    let operandB;
    let operator;
    let operationStart;
    for (let i = 0; i < parsed.length; i++) {
      let p = parsed[i];
      if (p === '+' || p === '-') {
        operandA = parsed[i - 1] || 0;
        operandB = parsed[i + 1] || 0;
        operator = p;
        operationStart = i - 1;
        break;
      }
    }
    let operandAValue = parseFloat(operandA);
    if(isNaN(operandAValue)) {
      operandAValue = 0
    }
    let operandBValue = parseFloat(operandB);
    if(isNaN(operandBValue)) {
      operandBValue = 0
    }
    let result = operator === '+' ? operandAValue + operandBValue : operandAValue - operandBValue;
    parsed.splice(operationStart, 3, result);
  }

  if (parsed.length === 1) {
    let number = parseFloat(parsed[0]);
    if (isNaN(number)) {
      return '0';
    }
    let stringToFixed = `${number.toFixed(2)}`;
    let string = `${parseFloat(stringToFixed)}`;
    if (string.length < stringToFixed.length) {
      return string;
    } else {
      return stringToFixed;
    }
  }
  return parsed.join(' ');
};

const evaluateSum = (values, body, repId, repIndex) => {
  let result = '';
  let [objectKey, propertyKey] = body.split('.');
  let data = values;
  if (repId) {
    data = data[repId][repIndex];
  }
  let obj = data[objectKey];
  if (!obj) {
    return result;
  }
  result = 0;
  let isArea = false;
  for (let i in obj) {
    let val = obj[i][propertyKey];
    if (!val) {
      continue;
    }
    if (typeof val === 'number') {
      result += parseFloat(val);
    } else if (typeof val === 'string') {
      if (isValueArea(val)) {
        isArea = true;
        let areaInCa = areaInCaUnits(val);
        result += areaInCa;
      } else {
        result += parseFloat(val)
      }
    }
  }
  if (isArea) {
    return printAreaValue(
      convertAreaToLargestUnits({
        ha: 0,
        a: 0,
        ca: result,
      })
    );
  }
  return result;
};

const isValueArea = (val) => {
  const areaValueRegex = /^(?:[0-9]+ha ?)?(?:[0-9]+a ?)?(?:[0-9]+ca)?$/;
  return areaValueRegex.test(val);
};

const evaluateInWords = (values, body, repId, repIndex) => {
  if (body === '') {
    return '';
  }
  let number = parseFloat(body);
  if (isNaN(number)) {
    let { result, valid } = evaluateFormula(values, body, repId, repIndex);
    if (!valid) {
      return '';
    }
    number = parseFloat(result);
  }
  return numberToWordsFrench(number);
};

const roundAmountToString = (amount) => {
  const amountString = `${amount}`
  if (amountString.includes('.')) {
    const [integer, decimal] = amountString.split('.')
    if (decimal.length > 2) {
      const roundedDecimal = roundCents(decimal)
      if (roundedDecimal === '100') {
        return `${parseInt(integer) + 1}`
      } else {
        return `${integer}.${roundedDecimal}`
      }
    } else {
      return amountString
    }
  } else {
    return amountString
  }
}

const roundCents = (decimalString) => {
  if (decimalString.length <= 2) {
    return decimalString
  }
  else {
    let rounded
    if (decimalString[2] >= 5) {
      rounded = (parseInt(decimalString.slice(0, 2)) + 1).toString()
    } else {
      rounded = decimalString.slice(0, 2)
    }
    if (rounded.length === 1) {
      return `0${rounded}`
    } else {
      return `${rounded}`
    }
  }
}

const numberToWordsFrench = (number, type) => {
  let result, iteration, iterationText, hundreds, hundredsText;

  if (type === 'euro') {
    number = parseFloat(roundAmountToString(number))
  }
  let numberString = `${number}`;
  if (numberString.includes('.')) {
    let [integer, decimal] = numberString.split('.');
    let decimalInWords = '';
    if (type === 'euro' && decimal.length === 1) {
      decimal += '0'
    } else if (type === 'euro' && decimal.length > 2) {
      decimal = decimal.substring(0, 2)
    }
    if (type !== 'euro') {
      while (decimal[0] === '0') {
        decimalInWords += 'zéro ';
        decimal = decimal.substring(1);
      }
    }
    decimalInWords += numberToWordsFrench(parseInt(decimal));
    if (type === 'euro') {
      const eurosInWords = numberToWordsFrench(parseInt(integer))
      const lastWord = eurosInWords.split(' ').pop()
      const allEuroPrefixWords = [...euroPrefixIterationWords, ...euroPrefixIterationWords.map(w => `${w}s`)]
      return `${eurosInWords} ${allEuroPrefixWords.includes(lastWord) ? "d'" : ''}euro${parseInt(integer) > 1 ? 's' : ''} et ${decimalInWords} centime${decimal > 1 ? 's' : ''}`;
    }
    return `${numberToWordsFrench(
      parseInt(integer)
    )} virgule ${decimalInWords}`;
  }

  if (number === 0) {
    return 'zéro';
  }

  const originalNumber = number;

  result = '';
  iteration = 1;

  while (number > 0) {
    hundreds = number % 1000;
    number = Math.floor(number / 1000);

    if (hundreds > 0) {
      // -- 3 digits to text
      hundredsText = getHundredsText(hundreds);
      if (`${originalNumber}` !== "80000" && number === 0 && hundredsText === 'quatre-vingt') {
        hundredsText += 's';
      }
      if (iteration > 1) {
        // -- mille, million, …
        iterationText = getIterationText(iteration);
        if ((iterationText || '').trim() !== 'mille' && (hundredsText || '').trim() !== 'un') {
          iterationText += 's';
        }
        hundredsText = hundredsText + iterationText + ' ';
        if ((hundredsText || '').trim() === 'un mille') {
          hundredsText = ' mille ';
        }
      }

      result = hundredsText + result;
    }
    iteration = iteration + 1;
  }

  let inWordsResult = result.trim()
  if (type === 'euro') {
    const lastWord = inWordsResult.split(' ').pop()
    const allEuroPrefixWords = [...euroPrefixIterationWords, ...euroPrefixIterationWords.map(w => `${w}s`)]
    inWordsResult += ` ${allEuroPrefixWords.includes(lastWord) ? "d'" : ''}euro` + (parseInt(originalNumber) > 1 ? 's' : '')
  }

  return inWordsResult
};

const numberToWords = (number, language = 'fr', type = '') => {
  if (language === 'fr') {
    return numberToWordsFrench(number, type);
  }
  return `Translation to ${language} is not yet supported`;

}

const getHundredsText = (number) => {
  let result, hundreds, tens;
  result = '';

  hundreds = Math.floor(number / 100);
  if (hundreds > 0) {
    number = number % 100;
    if (hundreds > 1) {
      // -- deux cents, trois cents, …
      result = result + getLessThanTwentyText(hundreds) + ' cent' + (number > 0 ? '' : 's');
    } else {
      result = result + 'cent';
    }
  }

  if (number > 0) {
    if (hundreds > 0) {
      // -- space after "cent(s)"
      result = result + ' ';
    }

    if (number < 20) {
      // -- < 20 ⇒ look up
      result = result + getLessThanTwentyText(number);
    } else {
      tens = Math.floor(number / 10);
      number = number % 10;

      switch (tens) {
        case 7:
          // -- soixante dix, soixante onze, …
          result = result + 'soixante';
          number = number + 10;
          break;
        case 8:
          if (number > 0) result = result + 'quatre-vingt';
          else result = result + 'quatre-vingt';
          break;
        case 9:
          // -- quatre-vingt dix, quatre-vingt onze, …
          result = result + 'quatre-vingt';
          number = number + 10;
          break;
        default:
          // -- vingt, trente, quarante, …
          result = result + getTensText(tens);
      }

      if (number > 0) {
        if (number === 1 && tens !== 8) result = result + ' et un';
        else result = result + '-' + getLessThanTwentyText(number);
      }
    }
  }

  return result;
};

const getLessThanTwentyText = (number) => {
  switch (number) {
    case 1:
      return 'un';
    case 2:
      return 'deux';
    case 3:
      return 'trois';
    case 4:
      return 'quatre';
    case 5:
      return 'cinq';
    case 6:
      return 'six';
    case 7:
      return 'sept';
    case 8:
      return 'huit';
    case 9:
      return 'neuf';
    case 10:
      return 'dix';
    case 11:
      return 'onze';
    case 12:
      return 'douze';
    case 13:
      return 'treize';
    case 14:
      return 'quatorze';
    case 15:
      return 'quinze';
    case 16:
      return 'seize';
    case 17:
      return 'dix-sept';
    case 18:
      return 'dix-huit';
    case 19:
      return 'dix-neuf';
    default:
      return '';
  }
};

const getTensText = (tens) => {
  switch (tens) {
    case 2:
      return 'vingt';
    case 3:
      return 'trente';
    case 4:
      return 'quarante';
    case 5:
      return 'cinquante';
    case 6:
      return 'soixante';
    default:
      return '';
  }
};

const getIterationText = (iteration) => {
  switch (iteration) {
    case 1:
      return '';
    case 2:
      return ' mille';
    case 3:
      return ' million';
    case 4:
      return ' milliard';
    case 5:
      return ' billion';
    case 6:
      return ' billiard';
    case 7:
      return ' trillion';
    case 8:
      return ' trilliard';
    case 9:
      return ' quadrillion';
    default:
      return ' ??? ';
  }
};

const euroPrefixIterationWords = [
  "million",
  "milliard",
  "billion",
  "billiard",
  "trillion",
  "trilliard",
  "quadrillion",
]

// Get file data
const getFileData = (file, fileType = '', cb) => {
  if (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = file.name.split('.');
        const format = components[components.length - 1];
        components.splice(components.length - 1, 1);
        const name = components.join('.');
        const type = file.type ? file.type : fileType;
        resolve({
          data: e.target.result,
          name: name,
          format: format,
          type: type,
          size: file.size,
        });
        cb &&
          cb({ data: e.target.result, name: name, format: format, type: type, size: file.size });
      };
      reader.onerror = (err) => {
        console.log('reader on error', err);
        reject();
      };
      reader.readAsDataURL(file);
    });
  }
};

// Get first letter of the string
const getFirstLetter = (text) => {
  if (typeof text !== 'string') return;
  return text.slice(0, 1);
};

const areaInCaUnits = (value) => {
  let parsed = parseAreaValue(value);
  return parsed.ha * 10000 + parsed.a * 100 + parsed.ca;
};

const convertAreaToLargestUnits = (parsed) => {
  while (parsed.ca > 100) {
    parsed.a += Math.floor(parsed.ca / 100);
    parsed.ca = parsed.ca % 100;
  }
  while (parsed.a > 100) {
    parsed.ha += Math.floor(parsed.a / 100);
    parsed.a = parsed.a % 100;
  }
  return parsed;
};

const parseAreaValue = (value) => {
  const parsed = {
    ha: 0,
    a: 0,
    ca: 0,
  };
  if (!value) {
    return parsed;
  }
  value = value.replace(/s/g, '');
  if (typeof value === 'number') {
    parsed.ca = value;
    return convertAreaToLargestUnits(parsed);
  }

  let parsedNumber = parseInt(value);
  if (value === `${parsedNumber}`) {
    parsed.ca = parsedNumber;
    return convertAreaToLargestUnits(parsed);
  }
  let components = value.split('a');

  for (let i = 0; i < components.length; i++) {
    if (components[i].includes('h')) {
      let val = parseInt(components[i].replace(/h/g, ''));
      parsed.ha = isNaN(val) ? 0 : val;
    } else if (components[i].includes('c')) {
      let val = parseInt(components[i].replace(/c/g, ''));
      parsed.ca = isNaN(val) ? 0 : val;
    } else if (components[i].length > 0) {
      let val = parseInt(components[i]);
      parsed.a = isNaN(val) ? 0 : val;
    }
  }

  return convertAreaToLargestUnits(parsed);
};

const printAreaValue = (parsedArea) => {
  let val = '';
  if (parsedArea.ha > 0) {
    val += `${parsedArea.ha}ha`;
  }
  if (parsedArea.a > 0) {
    val += val.length > 0 ? ' ' : '';
    val += `${parsedArea.a}a`;
  }
  if (parsedArea.ca > 0) {
    val += val.length > 0 ? ' ' : '';
    val += `${parsedArea.ca}ca`;
  }
  return val;
};

// Convert bytes to sizes(kb, mb...)
const bytesToSize = (bytes) => {
  const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte'];
  const unit = Math.floor(Math.log(bytes) / Math.log(1024));
  return new Intl.NumberFormat('en', {
    style: 'unit',
    unit: units[unit],
    maximumFractionDigits: 1,
  }).format(bytes / 1024 ** unit);
};

const canUseCoverPageSettings = (user, partner) => {
  if (partner === 'jurisur') {
    return user && user.delegation
  } else if (partner === 'kel') {
    return true
  }
  return false
};

const partnerUsesV1UI = (partner) => {
  return isMlsPartner(partner) || partner === 'alphabet';
};

const statusLabelForAr24Sender = (user) => {
  return AR24_SENDER_STATUS_LABEL[user?.status]
};

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}


const agencyLogoUrl = (agency) => {
  if (!agency) {
    return ''
  }
  let logoUrl = ''
  if (agency.logo) {
    logoUrl = agency.logo
  } else if (agency.manufacturers_logo) {
    logoUrl = agency.manufacturers_logo
  }
  let isRelativeUrl = false
  if (logoUrl.startsWith('..')) {
    isRelativeUrl = true
    logoUrl = logoUrl.replace('..', '')
  }
  if (logoUrl.startsWith('/')) {
    isRelativeUrl = true
    logoUrl = logoUrl.replace('/', '')
  }

  if (!isRelativeUrl) {
    return logoUrl
  }

  let { baseUrl } = agency
  if (!baseUrl) {
    return ''
  }
  if (!baseUrl.endsWith('/')) {
    baseUrl = `${baseUrl}/`
  }
  return `${baseUrl}${logoUrl}`
}

/**
 *
 * @param {Object} user
 * @param {String} property "id", "name", "address", "zip", "city", "phone", "email"
 */
const manufacturerProperty = (agency, property) => {
  if (!agency) {
    return ''
  }
  switch (property) {
    case 'id':
      return agency.id || agency.manufacturers_id || ''
    case 'name':
      return agency.manufacturers_name || agency.name || ''
    case 'address':
      return agency.address || agency.adresse || ''
    case 'zip':
      return agency.postal_code || ''
    case 'city':
      return agency.city || ''
    case 'phone':
      return agency.phone || agency.telephone || ''
    case 'email':
      return agency.email || ''
    default:
      throw (new Error(`Invalid manufacturer property ${property}`))
  }
}

const adminProperty = (user, property) => {
  switch (property) {
    case 'phone':
      return user?.mobile_phone || user?.phone || user?.telephone || ''
    case 'email':
      return user?.email || ''
    default:
      throw (new Error(`Invalid admin property ${property}`))
  }
}

const fileNameWithoutExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1) return fileName
  return fileName.substring(0, lastDotIndex)
}

const dateDisplayFormat = "DD/MM/YYYY HH:mm"
const dateOnlyDisplayFormat = "DD/MM/YYYY"
const timeDisplayFormat = "HH[H]mm"


const isFeatureAuthorized = ({ userClaims, rule, resource }) => {
  let isAllowed = false
  if (!userClaims) {
    return false
  }

  if (rule === 'any_admin') { // admin access in any authorized manufacturer
    if (userClaims.authorized_manufacturers?.length > 0) {
      const adminManufacturers = userClaims.authorized_manufacturers.filter(m => m.access === 'admin')
      isAllowed = adminManufacturers.length > 0
    }
  } else if (rule === 'current_full') { // full access in main manufacturer
    if (userClaims.authorized_manufacturers?.length > 0) {
      const authorizedManufacturer = userClaims?.authorized_manufacturers.find(m => `${m.id}` === `${userClaims?.manufacturer_id}`)
      if (authorizedManufacturer?.access === 'full' || authorizedManufacturer?.access === 'admin') {
        isAllowed = true
      }
    }
  } else if (rule === 'any') {
    return true
  }

  return isAllowed
}

const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (typeof arr1[i] === 'object' || typeof arr2[i] === 'object') {
      if (Array.isArray(arr1[i]) && !Array.isArray(arr2[i])) {
        return false
      }
      if (!Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
        return false
      }
      if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
        if (!areArraysEqual(arr1[i], arr2[i])) {
          return false
        }
      }
      if (!areObjectsEqual(arr1[i], arr2[i])) {
        return false
      }
    } else {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }
  }
  return true
}

const areObjectsEqual = (a, b) => {
  if (!a || !b) {
    return false
  }
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (aKeys.length !== bKeys.length) {
    return false
  }
  for (let i = 0; i < aKeys.length; i++) {
    if (aKeys[i] !== bKeys[i]) {
      return false
    }

    if (typeof a[aKeys[i]] === 'object' || typeof b[bKeys[i]] === 'object') {
      if (Array.isArray(a[aKeys[i]]) && !Array.isArray(b[bKeys[i]])) {
        return false
      } else if (!Array.isArray(a[aKeys[i]]) && Array.isArray(b[bKeys[i]])) {
        return false
      } else if (Array.isArray(a[aKeys[i]]) && Array.isArray(b[bKeys[i]])) {
        if (!areArraysEqual(a[aKeys[i]], b[bKeys[i]])) {
          return false
        }
      } else {
        if (!areObjectsEqual(a[aKeys[i]], b[bKeys[i]])) {
          return false
        }
      }
    } else if (a[aKeys[i]] !== b[bKeys[i]]) {
      return false
    }
  }
  return true
}

/**
 *
 * @param {'documents'|'standard-templates'|'templates'} view
 * @param {any} userClaims
 * @returns
 */
const areFolderActionsAuthorized = (view, userClaims) => {
  if (view === 'documents') {
    return isFeatureAuthorized({ userClaims, rule: 'current_full', resource: 'document_folders_actions' })
  }
  if (view === 'standard-templates') {
    return isFeatureAuthorized({ userClaims, rule: 'current_full', resource: 'standard_template_folders_actions' })
  }
  if (view === 'templates') {
    return isFeatureAuthorized({ userClaims, rule: 'any_admin', resource: 'template_folders_actions' })
  }
  return false
}

const SIGNATORY_CATEGORIES = [{
  value: 'seller',
  label: 'Vendeur'
}, {
  value: 'owner',
  label: 'Propriétaire'
}, {
  value: 'acquirer',
  label: 'Acquéreur'
}, {
  value: 'lessor',
  label: 'Bailleur'
}, {
  value: 'tenant',
  label: 'Locataire'
}, {
  value: 'representative',
  label: 'Mandataire'
}, {
  value: 'surety',
  label: 'Cautionnaire'
}, {
  value: 'principal',
  label: 'Mandant'
}]

const CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV = {
  "signatory_categories": {
    "options": ["representative", "principal"],
    "counts": {
      "representative": {
        "min": 1,
        "max": 1,
        "minError": "Aucun mandataire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme mandataire"
      },
      "principal": {
        "min": 1,
        "max": 5,
        "minError": "Aucun mandant n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 5 mandants"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/4c9e6938-03db-4699-90f0-051d74719895",
    "fieldsMapping": {
      "representative": "Mandataire",
      "principal": "Vendeur_{index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

// const Cautionnement solidaire unilatéral
const CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_DEV = {
  "signatory_categories": {
    "options": ["surety"],
    "counts": {
      "surety": {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      "total": {
        "min": 1,
        "max": 1,
      }
    },
    "variablesMapping": {
      "surety": {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/57928ed8-2c35-49c7-9707-6213accb99e1",
    "fieldsMapping": {
      "surety": "Cautionnaire"
    },
    "maskPage": {
      "add": false
    }
  }
}
const CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_PRODUCTION = {
  "signatory_categories": {
    "options": ["surety"],
    "counts": {
      "surety": {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      "total": {
        "min": 1,
        "max": 1,
      }
    },
    "variablesMapping": {
      "surety": {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.fr/xzibit/api/v1/form-builders/592c39ed-b6ed-4183-b471-5085fe39fda3",
    "fieldsMapping": {
      "surety": "Cautionnaire"
    },
    "maskPage": {
      "add": false
    }
  }
}
// const Cautionnement solidaire à durée déterminée
const CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_DEV = {
  "signatory_categories": {
    "options": ["surety", "lessor"],
    "counts": {
      "surety": {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      "lessor": {
        "min": 1,
        "max": 9,
        "minError": "Aucun bailleur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 9 bailleurs"
      },
      "total": {
        "min": 2,
        "max": 10,
      }
    },
    "variablesMapping": {
      "surety": {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      },
      "lessor": {
        "do_sellers_customers_firstname": "firstname",
        "do_sellers_customers_lastname": "lastname",
        "do_sellers_customers_email_address": "email",
        "do_sellers_customers_telephone": "phone",
        "do_co_seller_customers_firstname": "firstname",
        "do_co_seller_customers_lastname": "lastname",
        "do_co_seller_customers_email_address": "email",
        "do_co_seller_customers_telephone": "phone"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/c587eed2-e2ee-4a60-be0b-fc202b9f0286",
    "fieldsMapping": {
      "surety": "Cautionnaire",
      "lessor": "Bailleur {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}
const CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_PRODUCTION = {
  "signatory_categories": {
    "options": ["surety", "lessor"],
    "counts": {
      "surety": {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      "lessor": {
        "min": 1,
        "max": 9,
        "minError": "Aucun bailleur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 9 bailleurs"
      },
      "total": {
        "min": 2,
        "max": 10,
      }
    },
    "variablesMapping": {
      "surety": {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      },
      "lessor": {
        "do_sellers_customers_firstname": "firstname",
        "do_sellers_customers_lastname": "lastname",
        "do_sellers_customers_email_address": "email",
        "do_sellers_customers_telephone": "phone",
        "do_co_seller_customers_firstname": "firstname",
        "do_co_seller_customers_lastname": "lastname",
        "do_co_seller_customers_email_address": "email",
        "do_co_seller_customers_telephone": "phone"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.fr/xzibit/api/v1/form-builders/03a8427b-3b53-4eb6-9c72-e6a792c35980",
    "fieldsMapping": {
      "surety": "Cautionnaire",
      "lessor": "Bailleur {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const SIGNATURE_MODE_FOR_TEMPLATE = {
  "development": {
    "R60kfjH51qyO5XIryVsA": "manual",
  },
  "staging": {
    "SWZWslvkqbQoh1dbQRYs": "manual",
    "9oZcK02GXYxoeU6IB0WP": "manual",
    "sa72j1oDfhtWgcQDClri": "manual",
    "pzIhC7RB0x9qgt1IuCvt": "manual",
    "WDegrtR9R9wZ1dwrlAJo": "manual",
    "vFvAG6HCN0wEt7cfaih0": "manual"
  },
  "production": {
    "lfUVYCuuOHlaDpC8nuBq": "manual",
    "5w6pTjWxaFnBHNgi83wM": "manual",
    "YEETnqdtdP08NEHGMqOq": "manual",
    "J75GpoEUQ3WHfiwwhpWR": "manual",
    "EiNQTISS8distfO2bEuh": "manual",
    "hk48e2VTcLnxTIwJxVJo": "manual"
  }
}

const SIGNATORY_RULES_FOR_TEMPLATE = {
  "development": {
    "R60kfjH51qyO5XIryVsA": CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV
  },
  "staging": {
    "SWZWslvkqbQoh1dbQRYs": CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV,
    "9oZcK02GXYxoeU6IB0WP": CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV,
    "sa72j1oDfhtWgcQDClri": CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV,
    "pzIhC7RB0x9qgt1IuCvt": CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV,
    "WDegrtR9R9wZ1dwrlAJo": CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_DEV,
    "vFvAG6HCN0wEt7cfaih0": CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_DEV
  },
  "production": {
    "EiNQTISS8distfO2bEuh": CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_PRODUCTION,
    "hk48e2VTcLnxTIwJxVJo": CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_PRODUCTION
  }
}

const ATTACHMENT_RULES_FOR_TEMPLATE = {
  "development": {
    "R60kfjH51qyO5XIryVsA": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    }
  },
  "staging": {
    "SWZWslvkqbQoh1dbQRYs": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
    "9oZcK02GXYxoeU6IB0WP": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
    "sa72j1oDfhtWgcQDClri": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
    "pzIhC7RB0x9qgt1IuCvt": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
    "WDegrtR9R9wZ1dwrlAJo": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
    "vFvAG6HCN0wEt7cfaih0": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
  },
  "production": {
    "EiNQTISS8distfO2bEuh": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
    "hk48e2VTcLnxTIwJxVJo": {
      "mainDocument": "TO_BE_SIGNED",
      "attachments": "ATTACHED"
    },
  }
}

const folderItemsForMobileSidebar = (foldersSource, rootFolders, activeFolder) => {

  const items = [
    {
      id: 'all',
      label: 'Dossier racine',
    },
  ]
  const foldersChildrenMap = {}
  for (let folder of foldersSource) {
    if (folder.parentFolder) {
      if (!foldersChildrenMap[folder.parentFolder]) {
        foldersChildrenMap[folder.parentFolder] = []
      }
      foldersChildrenMap[folder.parentFolder].push(folder.id)
    }
  }

  const foldersWithParentPathsAndChildren = foldersSource.map(folder => {
    const parentPath = []
    let parentFolder = folder.parentFolder
    while (parentFolder) {
      const parentFolderId = parentFolder
      const parent = foldersSource.find(f => f.id === parentFolderId)
      if (!parent) {
        break
      }
      parentPath.unshift(parent.id)
      parentFolder = parent.parentFolder
    }
    return {
      ...folder,
      parentPath,
      children: foldersChildrenMap[folder.id] || []
    }
  })

  for (let i = 0; i < rootFolders.length; i++) {
    const rootFolder = foldersWithParentPathsAndChildren.find(folder => folder.id === rootFolders[i].id)
    if (!rootFolder) {
      continue
    }
    const activeFolderData = foldersWithParentPathsAndChildren.find(folder => folder.id === activeFolder)
    if (activeFolder === rootFolder.id) {
      items.push({ id: rootFolder.id, label: rootFolder.name, hasChildren: activeFolderData.children.length > 0, isOpen: true })
      const activeFolderChildren = activeFolderData.children.map(childId => foldersWithParentPathsAndChildren.find(folder => folder.id === childId))
      items.push(...activeFolderChildren.map(folder => ({ id: folder.id, label: folder.name, hasChildren: folder.children.length > 0 })))
    } else if (activeFolderData?.parentPath.includes(rootFolder.id)) {
      const parents = activeFolderData.parentPath.map(parentId => foldersWithParentPathsAndChildren.find(folder => folder.id === parentId))
      items.push(...parents.map(parentFolder => ({ id: parentFolder.id, label: parentFolder.name, hasChildren: parentFolder.children.length > 0, isOpen: true })))
      items.push({ id: activeFolderData.id, label: activeFolderData.name, hasChildren: activeFolderData.children.length > 0, isOpen: true })
      const activeFolderChildren = activeFolderData.children.map(childId => foldersWithParentPathsAndChildren.find(folder => folder.id === childId))
      items.push(...activeFolderChildren.map(folder => ({ id: folder.id, label: folder.name, hasChildren: folder.children.length > 0 })))
    } else {
      items.push({ id: rootFolder.id, label: rootFolder.name, hasChildren: rootFolder.children.length > 0 })
    }
  }
  return items
}

const emptyLinesValuesForTemplate = (template, values) => {
  const elValues = {}
  // find all variables in template, if value is not present in values, add according EMPTY_LINES value to elValues based on variable type
  for (let i = 0; i < template.sections.length; i++) {
    const section = template.sections[i]
    if (section.variable) {
      if (!values[section.variable] && EMPTY_LINES[section.data_type]) {
        elValues[section.variable] = EMPTY_LINES[section.data_type]
      }
    }
    if (section.variables) {
      for (let j = 0; j < section.variables.length; j++) {
        const variable = section.variables[j]
        if (!values[variable.variable] && EMPTY_LINES[variable.type]) {
          elValues[variable.variable] = EMPTY_LINES[variable.type]
        }
      }
    }
  }
  return elValues
}

const defaultAttachmentsForDocument = (defaultAttachments = {}, template = {}, documentValues = {}) => {
  let atts = Object.keys(defaultAttachments).map(key => ({
    ...defaultAttachments[key],
    id: key
  }))
  atts = atts
  .filter(att => template?.default_attachments?.includes(att.id))
  .filter(att => {
    if(!att.conditions || att.conditions.length === 0) return true
    let conditions = att.conditions
    const validConditionRelations = ['or', 'and']
    let conditionsRelation = att.conditions_relation
    if(!validConditionRelations.includes(conditionsRelation)) {
      conditionsRelation = 'or'
    }
    let valid = conditionsRelation === 'or' ? false : true
    for(let i = 0; i < conditions.length; i++) {
      let condition = conditions[i]
      if(conditionsRelation === 'or') {
        if(`${documentValues[condition.variable]}` === `${condition.value}`) {
          valid = true
          break
        }
      } else if(conditionsRelation === 'and') {
        if(`${documentValues[condition.variable]}` !== `${condition.value}`) {
          valid = false
          break
        }
      } else {
        valid = false
        break
      }
    }
    return valid
  })
  return atts
}

const arrayFromObjectWithOrderedIds = (object, orderedIds) => {

  let orderedArray = orderedIds.map(id => object[id] ? { ...object[id], id } : null).filter(item => item)
  for(let key in object) {
    if(!orderedIds.includes(key)) {
      orderedArray.push(object[key])
    }
  }
  return orderedArray
}

const arrayWithOrderedIds = (array, orderedIds) => {
  let orderedArray = orderedIds.map(id => array.find(item => item.id === id)).filter(item => item)
  for(let item of array) {
    if(!orderedIds.includes(item.id)) {
      orderedArray.push(item)
    }
  }
  return orderedArray
}
const searchArrayWithProperties = (array, searchTerm, properties) => {
  searchTerm = searchTerm.trim()
  if (!searchTerm) {
    return array
  }
  return array.filter(item => {
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i]
      const propertyComponents = property.split('.')
      let value = item
      for (let j = 0; j < propertyComponents.length; j++) {
        value = value[propertyComponents[j]]
        if (!value) {
          break
        }
      }
      const searchComponents = searchTerm.split(' ').map(s => s.trim().toLowerCase()).filter(s => s)
      const valueString = `${value}`.toLowerCase()
      if (searchComponents.every(s => valueString.includes(s))) {
        return true
      }
    }
    return false
  })
}

const getContactKeyWithoutPropName = (key) => {
  let names = ['firstname', 'lastname', 'telephone', 'email']
  for(let i = 0; i < names.length; i++) {
    if(key.includes(names[i])) {
      return names[i]
    }
  }
}

const getContactPrefix = (key) => {
  const prefixes = DOC_CONTACT_PREFIXES
  for(let i = 0; i < prefixes.length; i++) {
    if(key.substring(0, prefixes[i].length + 1) === `${prefixes[i]}_`) {
      return prefixes[i]
    }
  }
}

const getActiveContactsFromArray = (array) => {
  const contacts = []
  for(let i in array) {
    contacts.push(...getActiveContactsFromData(array[i]))
  }
  return contacts
}

const getActiveContactsFromData = (data) => {
  if(!data) return []
  const contacts = []
  const currentContacts = {}
  for(let key in data) {
    if(Array.isArray(data[key])) {
      contacts.push(...getActiveContactsFromArray(data[key]))
      continue
    }
    if(DOC_CONTACTS_KEYS.includes(key)) {
      const keyWithoutPropName = getContactKeyWithoutPropName(key)
      const contactPrefix = getContactPrefix(key)
      if(!currentContacts[contactPrefix]) {
        currentContacts[contactPrefix] = {}
      }
      currentContacts[contactPrefix][keyWithoutPropName] = data[key]
    }
  }
  for(let key in currentContacts) {
    contacts.push(currentContacts[key])
  }
  const parsedContacts = contacts.map(contact => {
    const parsedContact = {}
    for(let key in contact) {
      const keyWithoutPropName = getContactKeyWithoutPropName(key)
      if(keyWithoutPropName) {
        parsedContact[keyWithoutPropName] = contact[key]
      }
    }
    if(parsedContact.telephone) {
      parsedContact.telephone = parsedContact.telephone.replace(/[^0-9+]/g, '')
      while(parsedContact.telephone[0] === '0') {
        parsedContact.telephone = parsedContact.telephone.substring(1)
      }
    }
    return parsedContact
  })
  const uniqueParsedContacts = []
  for(let i = 0; i < parsedContacts.length; i++) {
    if(!uniqueParsedContacts.find(contact =>
      contact.email === parsedContacts[i].email &&
      contact.telephone === parsedContacts[i].telephone &&
      contact.firstname === parsedContacts[i].firstname &&
      contact.lastname === parsedContacts[i].lastname
    )) {
      uniqueParsedContacts.push(parsedContacts[i])
    }
  }
  return uniqueParsedContacts
}

const VIALINK_SIGNATURE_DOCUMENT_MODE = {
  "TO_BE_SIGNED": "TO_BE_SIGNED",
  "ATTACHED": "ATTACHED",
}


const convertTemplateToMetaBlocks = (template) => {
  if(!template?.sections) {
    return []
  }

  const extractMetaBlocks = (sections) => {
    const metaSections = []
    const currentMetaSectionStack = [{
        sections: [],
        repeatableIds: [],
        isMetaSection: true
    }]
    for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        section.index = i
        const sectionRepeatableIds = section.repeatable_section_ids || (section.repeatable_section_id ? [section.repeatable_section_id] : [])
        // check if section is sibling or direct child of current_full
        if (currentMetaSectionStack.length === 0) {
            const newMetaSection = {
                sections: [section],
                repeatableIds: sectionRepeatableIds,
                isMetaSection: true
            }
            currentMetaSectionStack.push(newMetaSection)
        } else if (areArraysEqual(sectionRepeatableIds, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
            currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(section)
        } else if (sectionRepeatableIds.length > currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length) {
            // validate if section is direct child of current
            if (sectionRepeatableIds.length === currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length + 1 && currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length > 0 && areArraysEqual(sectionRepeatableIds.slice(0, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length), currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                const newMetaSection = {
                    sections: [section],
                    repeatableIds: sectionRepeatableIds,
                    isMetaSection: true
                }
                currentMetaSectionStack.push(newMetaSection)
            } else if (currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length === 0) {

                const lastMetaSection = currentMetaSectionStack.pop()
                if (lastMetaSection.sections.length > 0) {
                    metaSections.push(lastMetaSection)
                }
                const newMetaSection = {
                    sections: [section],
                    repeatableIds: sectionRepeatableIds,
                    isMetaSection: true
                }
                currentMetaSectionStack.push(newMetaSection)
            } else {
                throw new Error('Invalid section repeatable ids')
            }
        } else if (sectionRepeatableIds.length < currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length) {
            // jump out of current stack until we find a matching parent 
            while (currentMetaSectionStack.length > 0 && (sectionRepeatableIds.length < currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length)) {
                const closingSection = currentMetaSectionStack.pop()
                if (currentMetaSectionStack.length === 0) {
                    metaSections.push(closingSection)
                } else {
                    currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
                }
            }
            if (currentMetaSectionStack.length > 0) {
                if (areArraysEqual(sectionRepeatableIds, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                    currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(section)
                } else {
                    const newMetaSection = {
                        sections: [section],
                        repeatableIds: sectionRepeatableIds,
                        isMetaSection: true
                    }
                    currentMetaSectionStack.push(newMetaSection)
                }
            } else {
                const newMetaSection = {
                    sections: [section],
                    repeatableIds: sectionRepeatableIds,
                    isMetaSection: true
                }
                currentMetaSectionStack.push(newMetaSection)
            }
        } else {
            // are equal length but not equal
            const closingSection = currentMetaSectionStack.pop()
            if (currentMetaSectionStack.length === 0) {
                metaSections.push(closingSection)
            } else {
                currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
            }
            const newMetaSection = {
                sections: [section],
                repeatableIds: sectionRepeatableIds,
                isMetaSection: true
            }
            currentMetaSectionStack.push(newMetaSection)
        }
    }
    while (currentMetaSectionStack.length > 0) {
        const closingSection = currentMetaSectionStack.pop()
        if (currentMetaSectionStack.length === 0) {
            metaSections.push(closingSection)
        } else {
            currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
        }
    }
    return metaSections
  }

  const mSections = extractMetaBlocks(template.sections)
  return mSections
}

const duplicatableValuesArray = (values, repSectionIds, repIndices) => {
  const repeatableSectionIds = repSectionIds ? [...repSectionIds] : []
  const repetitionIndices = repIndices ? [...repIndices] : []
  
  let vals = {...values}
  for(let i = 0; i < repeatableSectionIds.length; i++) {
    if (!vals[repeatableSectionIds[i]]) {
      vals[repeatableSectionIds[i]] = [{}]
    }
    vals = vals[repeatableSectionIds[i]]
    if(i < repSectionIds.length - 1 && repetitionIndices?.[i] !== undefined) {
      vals = vals[repetitionIndices[i]]
    }
  }
  return vals || [{}]
}

const calculateDocumentProgress = (document, template) => {
  const documentValues = document.values || {}
  const extractSummaryRowsFromMetaSection = (metaSection, parentRepeatableIds, parentRepetitionIndices) => {
    const rows = []
    const { sections } = metaSection
    if (metaSection.repeatableIds && metaSection.repeatableIds.length > 0) {
        const repetitionValuesArray = duplicatableValuesArray(documentValues, [...(metaSection.repeatableIds || [])], parentRepetitionIndices)
        if (!repetitionValuesArray || repetitionValuesArray.length === 0) return []
        repetitionValuesArray.forEach((repetitionValues, repetitionIndex) => {
            for (let i = 0; i < sections.length; i++) {
                const subSection = sections[i]
                if (subSection.isMetaSection) {
                    const subSectionRows = extractSummaryRowsFromMetaSection(subSection, [...(metaSection.repeatableIds || [])], [...(parentRepetitionIndices || []), repetitionIndex])
                    rows.push(...subSectionRows)
                    continue
                }
                if (!areSectionConditionsMet({ ...subSection, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }, documentValues)) {
                    continue
                }
                if (subSection.type.startsWith('heading')) {
                    rows.push({
                        title: subSection.content,
                    })
                }
                if (subSection.variable) {
                    const row = { variable: subSection, repeatableIds: metaSection.repeatableIds, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }
                    if (row.variable) {
                        row.value = repetitionValues[row.variable.variable]
                    }
                    rows.push(row)
                }
                if (subSection.variables) {
                    const variables = subSection.variables
                    for (let j = 0; j < variables.length; j++) {
                        const variable = variables[j]
                        const row = { variable, repeatableIds: metaSection.repeatableIds, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }
                        if (row.variable) {
                            row.value = repetitionValues[row.variable.variable]
                        }
                        rows.push(row)
                    }
                }
            }
        })

    } else {
        for (let i = 0; i < sections.length; i++) {
            const subSection = sections[i]
            if (subSection.isMetaSection) {
                const subSectionRows = extractSummaryRowsFromMetaSection(subSection, [...(metaSection.repeatableIds || [])], parentRepetitionIndices)
                rows.push(...subSectionRows)
                continue
            }
            if (!areSectionConditionsMet({ ...subSection, repetitionIndices: parentRepetitionIndices }, documentValues)) {
                continue
            }
            if (subSection.type.startsWith('heading')) {
                rows.push({
                    title: subSection.content,
                })
            }
            if (subSection.variable) {
                const row = { variable: subSection, repeatableIds: metaSection.repeatableIds, repetitionIndices: parentRepetitionIndices }
                if (row.variable) {
                    row.value = documentValues[row.variable.variable]
                }
                rows.push(row)
            }
            if (subSection.variables) {
                const variables = subSection.variables
                for (let j = 0; j < variables.length; j++) {
                    const variable = variables[j]
                    const row = { variable, repeatableIds: metaSection.repeatableIds, repetitionIndices: parentRepetitionIndices }
                    if (row.variable) {
                        row.value = documentValues[row.variable.variable]
                    }
                    rows.push(row)
                }
            }
        }
    }
    return rows
  }

  const templateMetaSections = convertTemplateToMetaBlocks(template)

  const rows = templateMetaSections.map((metaSection, metaSectionIndex) => extractSummaryRowsFromMetaSection(metaSection, [], []))
  const rowsFull = rows.flat()

  const sections = []
  let currentSummarySection = { title: '', fields: [] }
  for (let i = 0; i < rowsFull.length; i++) {
      const row = rowsFull[i]
      if (row.title) {
          if (currentSummarySection.fields.length > 0 || currentSummarySection.title) {
              sections.push(currentSummarySection)
          }
          currentSummarySection = { title: row.title, fields: [] }
      } else if (row.variable) {
          currentSummarySection.fields.push({ ...row.variable, value: row.value, repeatableIds: row.repeatableIds, repetitionIndices: row.repetitionIndices })
      }
  }
  if (currentSummarySection.fields.length > 0 || currentSummarySection.title) {
      sections.push(currentSummarySection)
  }

  let totalProgress = 0
  let variablesTotal = 0
  let variablesDone = 0
  const excludedProgressVars = [...CONTACT_ID_VARIABLES]

  for (let i = 0; i < sections.length; i++) {
      const section = sections[i]
      const fields = section.fields
      let sectionVariablesTotal = 0
      let sectionVariablesDone = 0
      for (let j = 0; j < fields.length; j++) {
          const field = fields[j]
          if (field.variable && !excludedProgressVars.includes(field.variable)) {
              sectionVariablesTotal++
              if ((typeof field.value === 'string' && field.value !== '') || (typeof field.value !== 'string' && field.value !== undefined)) {
                  sectionVariablesDone++
              }
          }
      }
      if (sectionVariablesTotal > 0) {
          section.progress = Math.round((sectionVariablesDone / sectionVariablesTotal) * 100)
      }
      variablesTotal += sectionVariablesTotal
      variablesDone += sectionVariablesDone
  }
  totalProgress = variablesTotal > 0 ? (variablesDone / variablesTotal) : 1
  return totalProgress
}

const printSize = (bytes) => {
  if (bytes > 1000000) {
    return `${Math.round(bytes / 1000000).toFixed(0)}MB`
  } else if (bytes > 1000) {
    return `${Math.round(bytes / 1000).toFixed(0)}KB`
  }
}

const mergePartialContentChange = (change, partialChange) => {
  const mergedChange = { ...change }
  if (change.content === partialChange.editedContent) {
    return mergedChange
  }
  if (partialChange.editedContent.length > partialChange.originalContent.length && change.content.includes(partialChange.editedContent)) {
    // when adding content check if it's already added and skip adding it again
    return mergedChange
  }
  mergedChange.content = mergedChange.content.replace(partialChange.originalContent, partialChange.editedContent)
  return mergedChange
}

const buildContentChanges = (partialContentChanges = [], source = {}, existingContentChanges = []) => {
  const contentChangesMap = {}
  const contentChanges = [...existingContentChanges] || []
  if (partialContentChanges === null) {
      partialContentChanges = []
  }
  // sort by length to prevent removing incorrect instances of content which might interfere with other partial changes
  const sortedPartialContentChanges = [...partialContentChanges].sort((a, b) => {
      // sort by length of original content in descending order
      return b.originalContent.length - a.originalContent.length
  })
  for (let partialChange of sortedPartialContentChanges) {
      let key = `${partialChange.sectionIndex}_${partialChange.listIndex}`
      if (!contentChangesMap[key]) {
          let fullOriginalContent
          // const source = templateObjectWithUniqueVarIndexes
          let section = source.sections[partialChange.sectionIndex]
          for (let c of contentChanges) {
              if (c.sectionIndex === partialChange.sectionIndex && c.listIndex === partialChange.listIndex) {
                  fullOriginalContent = c.content
                  break
              }
          }
          if (!fullOriginalContent) {
              if (partialChange.listIndex !== -1) {
                  fullOriginalContent = section.items[partialChange.listIndex].content
              } else if (section.content) {
                  fullOriginalContent = section.content
              } else {
                  fullOriginalContent = ''
              }
          }
          contentChangesMap[key] = {
              sectionIndex: partialChange.sectionIndex,
              listIndex: partialChange.listIndex,
              content: fullOriginalContent
          }
      }
      contentChangesMap[key] = mergePartialContentChange(contentChangesMap[key], {...partialChange})
  }
  for (let key in contentChangesMap) {
      let isNew = true
      for (let i in contentChanges) {
          if (contentChanges[i].sectionIndex === contentChangesMap[key].sectionIndex && contentChanges[i].listIndex === contentChangesMap[key].listIndex) {
              isNew = false
              contentChanges[i] = contentChangesMap[key]
              break
          }
      }
      if (isNew) {
          contentChanges.push(contentChangesMap[key])
      }
  }
  return contentChanges
}

export {
  sortedArrayFromObject,
  sorterWithPathAndOrder,
  getCreatedAtFromDocuments,
  dateValueFormat,
  urlSuffixForEnvironment,
  getAllParentFolders,
  folderHasSubfolders,
  folderHasTemplates,
  getFirstLevelSubfolders,
  sortArrayOfObjects,
  isOverflown,
  convertToTemplateObjWithUniqueVarIndexes,
  readFileAsync,
  base64toBlob,
  signatureAvailable,
  availableOn,
  copyToClipboard,
  blobToFile,
  areSectionConditionsMet,
  EVENT_TYPES,
  isCoverPageVariable,
  matchCoverPageVariables,
  canUseTemplateCategories,
  saveSortingToLS,
  getSorting,
  savePaginationDataToLS,
  getPaginationData,
  formulaResult,
  coverPageConfig,
  getFileData,
  getFirstLetter,
  parseAreaValue,
  printAreaValue,
  bytesToSize,
  isMlsPartner,
  canUseCoverPageSettings,
  partnerUsesV1UI,
  statusLabelForAr24Sender,
  insertFormulaResults,
  getTemplateFormulas,
  mergeDeep,
  isFeatureEnabled,
  agencyLogoUrl,
  dateDisplayFormat,
  timeDisplayFormat,
  dateOnlyDisplayFormat,
  numberToWords,
  manufacturerProperty,
  fileNameWithoutExtension,
  adminProperty,
  isFeatureAuthorized,
  NUMBER_FORMATTERS,
  areArraysEqual,
  areObjectsEqual,
  areFolderActionsAuthorized,
  SIGNATORY_CATEGORIES,
  SIGNATURE_MODE_FOR_TEMPLATE,
  SIGNATORY_RULES_FOR_TEMPLATE,
  ATTACHMENT_RULES_FOR_TEMPLATE,
  folderItemsForMobileSidebar,
  emptyLinesValuesForTemplate,
  defaultAttachmentsForDocument,
  arrayFromObjectWithOrderedIds,
  arrayWithOrderedIds,
  searchArrayWithProperties,
  getActiveContactsFromData,
  VIALINK_SIGNATURE_DOCUMENT_MODE,
  convertTemplateToMetaBlocks,
  calculateDocumentProgress,
  printSize,
  buildContentChanges,
};
