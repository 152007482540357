import { memo, useEffect, useState } from 'react'

import { EMPTY_LINES } from '../../constants'
import { Textarea } from '../ui_new'

const VariableTextarea = ({ fieldVariable, handle, val, linesAdded, onTextAreaInputChange, isHandleLocked }) => {
  const { variable, tooltip, type, idx} = fieldVariable

  const [value, setValue] = useState(val)

  useEffect(() => {
    setValue(val)
  }, [val])

  const handleBlur = () => {
    if(value !== val) {
      onTextAreaInputChange(value)
    }
  }


  return (
    <div className={`tooltip-wrapper textarea-el doc-variable-el ${linesAdded ? 'lines-added' : ''}`} data-id={idx}>
      <div style={{padding: '5px 0', position: 'relative'}}>
        <Textarea 
          id={variable}
          value={value}
          type={type} 
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          className={value ? 'not-empty' : ''}
          disabled={isHandleLocked(handle)} 
          tooltip={tooltip}
          placeholder={linesAdded ? EMPTY_LINES.textarea : ''}
        />
      </div>
    </div>
  )
}

export default memo(VariableTextarea)