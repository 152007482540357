import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Folder, Signature, Download, MoveToFolder, Pen, Duplicate, RegisteredMail } from '../../assets/icons';

import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsContext, UserContext, DocumentsFoldersContext, LoaderContext, RegisteredMailContext } from '../../context'
import { FEATURE, timeDisplayFormat, dateOnlyDisplayFormat, isFeatureEnabled, manufacturerProperty, calculateDocumentProgress } from '../../utils'
import { Alert, Checkbox, CustomTooltip } from '../ui_new'
import { useDocumentsActions, useSingleDocumentActions } from '../../hooks'
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import ActionsDropdown from '../UI/ActionsDropdown'
import { Archive } from '@material-ui/icons'
import { MoveSelectedItemsToFolderModal } from '../misc'
import { Delete, MoreVert, OpenInNew } from '@mui/icons-material';

const DocumentsTableRow = ({ id, doc, selectedDocuments, onSetSelectedDocuments, allChecked, onRequestSignature }) => {
  const { docsSelectedFilters, currentFilter, templates, getSingleTemplate } = useContext(DocumentsContext);
  const { setSendModalDefaultAttachments, setShowRegisteredMailSendModal, createAr24MailRequestFromDocument } = useContext(RegisteredMailContext)
  const { downloadDocuments, moveDocumentsToFolder, archiveDocuments } = useDocumentsActions(selectedDocuments, onSetSelectedDocuments);
  const { moveDocumentToDeleteDocuments, getCustomersFromData } = useSingleDocumentActions()
  const { setLoading, setShowGlobalResponseLoader } = useContext(LoaderContext)

  const { docFolders } = useContext(DocumentsFoldersContext)

  const { user } = useContext(UserContext)
  const [checked, setChecked] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [createdAtTime, setCreatedAtTime] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [modifiedAtTime, setModifiedAtTime] = useState('')
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  const history = useHistory()


  const { progress, getPreviewData, duplicateDocument } = useSingleDocumentActions()

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if (checked && selectedDocuments.length === 0) {
      setChecked(false)
    }
  }, [selectedDocuments, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if (allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // Set metadata
  useEffect(() => {
    if (doc?.meta?.created) {
      setCreatedAt(moment(doc.meta.created).format(dateOnlyDisplayFormat));
      setCreatedAtTime(moment(doc.meta.created).format(timeDisplayFormat));
    } else if (doc.createdAt) {
      setCreatedAt(moment(doc.createdAt).format(dateOnlyDisplayFormat));
      setCreatedAtTime(moment(doc.createdAt).format(timeDisplayFormat));
    }
    if (doc?.meta?.updated) {
      setModifiedAt(moment(doc.meta.updated).format(dateOnlyDisplayFormat));
      setModifiedAtTime(moment(doc.meta.updated).format(timeDisplayFormat));
    } else if (doc.updatedAt) {
      setModifiedAt(moment(doc.updatedAt).format(dateOnlyDisplayFormat));
      setModifiedAtTime(moment(doc.updatedAt).format(timeDisplayFormat));
    }
  }, [doc])

  // On document select
  const handleSelectDocument = () => {
    setChecked(!checked)
    if (checked) {
      onSetSelectedDocuments(prev => prev.filter(d => d.id !== id))
    } else {
      onSetSelectedDocuments(prev => [...prev, doc])
    }
  }

  // On go to doc
  const handleGoToDoc = () => {
    history.push(`/documents/${id}`)
  }

  // On drag start
  const handleDragStart = (e) => {
    const div = document.createElement('div')
    div.id = 'draggable-document-el'
    div.className = 'draggable-ghost-el-v2'
    div.innerText = `Déplacer le document ${doc.name}`
    document.getElementById('root').appendChild(div)
    e.dataTransfer.setData('document', JSON.stringify(doc))
    e.dataTransfer.setDragImage(div, 0, 0)
  }

  // On drag end
  const handleDragEnd = () => {
    if (document.getElementById('draggable-document-el')) {
      document.getElementById('draggable-document-el').remove()
    }
  }

  const documentProgressLabel = () => {
    let progress = 0
    if (doc.status === 'ready') {
      progress = 0 // hide progress for "ready" documents
    } else if (templates?.[doc.template]?.sections) {
      progress = calculateDocumentProgress(doc, templates[doc.template])
    } else if (doc.progress) {
      progress = doc.progress
    } 
    return progress > 0 ? `${(progress * 100).toFixed(0)}%` : ""
  }

  // On documents download
  const handleDocumentsDownload = async () => {
    await downloadDocuments([doc])
  }

  // On duplicate
  const handleDuplicate = async () => {

    setShowGlobalResponseLoader(true)
    try {
      let templateObject = templates[doc.template]
      if (!templateObject) {
        templateObject = await getSingleTemplate(doc.template, true)
      }
      const id = await duplicateDocument({
        doc: doc,
        linesAddedTo: [],
        template: templateObject,
      })
      history.push(`/documents/${id}`)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On archive
  const handleArchive = async () => {
    await archiveDocuments([doc])
    setShowDropdown(false)
  }

  const handleEditDocument = () => {
    // TODO verify, I can't think of anything else than just opening the document detail
    setShowDropdown(false)
    history.push(`/documents/${id}`)
  }

  // On open signatures-modal
  const handleRequestSignature = (e) => {
    e.preventDefault()
    onRequestSignature(doc)
  }

  // On open move to folder
  const handleOpenMoveToFolderModal = (e) => {
    e.preventDefault()
    setShowMoveToFolderModal(true)
  }
  // On close move to folder
  const handleCloseMoveToFolderModal = () => {
    setShowMoveToFolderModal(false)
  }

  // On documents move
  const handleDocumentsMove = (moveTo) => {
    moveDocumentsToFolder(moveTo)
    // onSetAllChecked(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete document
  const handleDeleteDocument = async () => {
    setShowGlobalResponseLoader(true)
    try {
      await moveDocumentToDeleteDocuments({
        ...doc,
        id: id,
      })
      setShowDeleteAlert(false)

    } catch (err) {
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }
  const handleOpenRegisteredMailModal = () => {
    const contacts = getCustomersFromData(doc.values, () => { })
    createAr24MailRequestFromDocument({ document: doc, documentValues: doc.values, documentCheckboxValues: doc.checkboxValues, documentName: doc.name, documentContacts: contacts })
  }
  return (
    <div className={`documents-table-row !pl-0 ${checked ? 'selected' : ''}`}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="documents-table-column documents-table-column__name !pl-0">
        <div className='flex gap-2 px-3'>
          <Checkbox checked={checked} onChange={handleSelectDocument} />
          <OpenInNew className='link-icon' onClick={handleGoToDoc} />
          <ActionsDropdown
            trigger={<button className="icon-btn icon-btn--transparent icon-btn--svg-xl more-vert-icon" onClick={() => setShowDropdown(!showDropdown)}><span className="svg-icon"><MoreVert fontSize='medium' /></span></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
          >
            <ul className="dashboard-column__dropdown">
              <li onClick={handleDocumentsDownload} className="edit"><span className="icon"><Download /></span>Télécharger</li>
              <li onClick={handleEditDocument} className="edit"><span className="icon"><Pen /></span>Editer le document</li>
              <li onClick={handleRequestSignature} className="edit"><span className="icon"><Signature /></span>Signatures</li>
              <li onClick={handleOpenRegisteredMailModal} className="edit"><span className="icon"><RegisteredMail /></span>Envoyer par LRE</li>
              <li onClick={handleOpenMoveToFolderModal} className="edit"><span className="icon"><MoveToFolder /></span>Déplacer</li>
              <li onClick={handleDuplicate} className='duplicate'><span className="icon"><Duplicate /></span>Dupliquer</li>
              <li onClick={handleArchive} className="archive"><span className="icon"><Archive /></span>Archiver</li>
              <li onClick={handleOpenDeleteAlert} className='trash'><span className="icon"><Delete /></span>Supprimer</li>
            </ul>
          </ActionsDropdown>
        </div>

        <CustomTooltip content={doc.name} position='top'>
          <p className='doc_name cursor-pointer pl-2 line-clamp-2' onClick={handleGoToDoc}>{doc.name}</p>
        </CustomTooltip>
      </div>
      <div className={`documents-table-column documents-table-column__status`}
        onClick={handleGoToDoc}>
        <p className={`status status--${doc.status}`}>
          <span className="text">
            {STATUS_LABELS_SHORT[doc.status]}
            {/* {doc.status === 'draft' && ` - ${(doc.progress * 100).toFixed(0)}%`} */}
          </span>
        </p>
      </div>
      <div className="documents-table-column documents-table-column__progress"
        onClick={handleGoToDoc}>
        <p>
          <span className="text">
            {documentProgressLabel()}
          </span>
        </p>
      </div>
      {isFeatureEnabled(FEATURE.INTERAGENCY) &&
        <div className="documents-table-column documents-table-column__author"
          onClick={handleGoToDoc}>
          <CustomTooltip content={doc.author ? `${doc.author.firstname} ${doc.author.lastname?.toUpperCase()}` : `agence (${manufacturerProperty(user?.manufacturer, 'name')})`}>
            <p className="line-clamp-2">
              {doc.author ? `${doc.author.firstname} ${doc.author.lastname?.toUpperCase()}` : `agence (${manufacturerProperty(user?.manufacturer, 'name')})`}
            </p>
          </CustomTooltip>
        </div>}
      {isFeatureEnabled(FEATURE.INTERAGENCY) &&
        <div className="documents-table-column documents-table-column__owner"
          onClick={handleGoToDoc}>
          <CustomTooltip content={manufacturerProperty(user.agencies ? user.agencies.find(a => `${manufacturerProperty(a, 'id')}` === `${doc.owner}`) : user?.manufacturer, 'name')} position='top'>
            <p className="line-clamp-2">
              {manufacturerProperty(user.agencies ? user.agencies.find(a => `${manufacturerProperty(a, 'id')}` === `${doc.owner}`) : user?.manufacturer, 'name')}
            </p>
          </CustomTooltip>
        </div>}
      <div className="documents-table-column documents-table-column__date"
        onClick={handleGoToDoc}>
        <div className="line-clamp-2">
          <p className='date'>{createdAt}</p>
          <p className='time'>{createdAtTime}</p>
        </div>
      </div>
      <div className="documents-table-column documents-table-column__date"
        onClick={handleGoToDoc}>
        <div className="line-clamp-2">
          <p className='date'>{modifiedAt}</p>
          <p className='time'>{modifiedAtTime}</p>
        </div>
      </div>
    
      {/* {currentFilter === 'all' && (
        <div className="go-to-document" onClick={handleGoToDoc}></div>
      )} */}

      {showMoveToFolderModal && (
        <MoveSelectedItemsToFolderModal
          onClose={handleCloseMoveToFolderModal}
          folders={docFolders}
          items={selectedDocuments}
          currentFolder={docsSelectedFilters.folder === 'all' ? null : docFolders.find(f => f.id === docsSelectedFilters.folder)}
          newFolderCreated={false} // remove this, improve
          onNewFolderCreated={() => { }} // remove this, improve
          onMove={handleDocumentsMove}
        />
      )}

      {showDeleteAlert && <Alert
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer ce document ?"
        onSubmit={handleDeleteDocument}
        deleteAlert={true}
      />}
    </div>
  )
}

export default DocumentsTableRow