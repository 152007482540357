import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'

import {
    Eye,
    Download,
    GripLines,
    Share,
    Pen,
    History,
    FileAlt,
    CloudUpload,
    Sync,
    FileDownload,
    Duplicate,
    Signature,
    RegisteredMail,
    Fave,
    EditNote,
    SwitchAccess,
    Draw
} from '../../assets/icons'
import {
    SingleDocumentVariables,
    SingleDocumentShare,
    SingleDocumentSign,
    SingleDocumentHistory,
    SingleDocumentSynthesis,
    SingleDocumentInject,
    SingleDocumentAttachments,
} from './'
import { CustomTooltip, Alert } from '../ui_new'
import { useSingleDocumentActions } from '../../hooks'
import { UserContext, LoaderContext, RegisteredMailContext, DocumentsContext } from '../../context'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { isMobile } from 'react-device-detect'
import { availableOn, defaultAttachmentsForDocument } from '../../utils'
import SingleDocumentSignVialink from './SingleDocumentSignVialink'
import { FEATURE, isFeatureEnabled } from '../../utils'
import { Replay, Star, SwitchAc } from '@material-ui/icons'
import SingleDocumentRenderSettings from './SingleDocumentRenderSettings'
import { AddCircleOutline, CheckCircle, Delete, Loop, MoreVert, PaletteOutlined, Redo, Save, Undo } from '@mui/icons-material'
import { AttachmentsContext } from '../../context/attachments/attachmentsState'

const SingleDocumentMain = ({
    singleDoc,
    templateData,
    documentValues,
    onValuesChange,
    documentCheckboxValues,
    onCheckboxValueChange,
    onCustomCoverChange,
    useCustomCover,
    onAgencyModalOpen,
    onNotariesModalOpen,
    onSave,
    onDocumentPreview,
    linesAdded,
    attachments,
    onAttachmentsShareUpdate,
    onAttachmentsShareSave,
    documentId,
    documentName,
    template,
    activeDocContacts,
    docSignatureRecipients,
    onSetDocumentValues,
    docHistory,
    docEvents,
    onVersionRestore,
    onFetchHistory,
    onResetSetup,
    view,
    onSetView,
    onSetDocumentAttachments,
    onSetTab,
    onToggleSidePanel,
    isSidePanelOpen,
    isContentEditable,
    onToggleContentEditable,
    onSectionContentChanged,
    onDocumentDownload,
    templateMetaSections,
    previewSettings,
    onPreviewRefresh,
    onRenderSettingsSave,
    progress,
    onCreateStandardTemplate,
    onNavigateToEmptyVariable,
    renderOptions
}) => {
    const { moveDocumentToDeleteDocuments, duplicateDocument, downloadDocumentData, immocloudUploadAvailable, uploadToCloud } = useSingleDocumentActions()
    const { immocloudConfig, hideActionBar, agency, user, partner, siteConfigs } = useContext(UserContext)
    const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
    const { createAr24MailRequestFromDocument } = useContext(RegisteredMailContext)
    const { defaultAttachments, setPrefilledAttachmentVariable } = useContext(AttachmentsContext)
    const [showShareModal, setShowShareModal] = useState(false)
    const [showRenderSettingsModal, setShowRenderSettingsModal] = useState(false)
    const [showSignModal, setShowSignModal] = useState(false)
    const [showDocumentNotCompletePrompt, setShowDocumentNotCompletePrompt] = useState(false)
    const [showHistoryModal, setShowHistoryModal] = useState(false)
    const [showSynthesisModal, setShowSynthesisModal] = useState(false)
    const [showInjectModal, setShowInjectModal] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showDocxDownloadAlert, setShowDocxDownloadAlert] = useState(false)
    const [showMoreActions, setShowMoreActions] = useState(false)
    const history = useHistory()

    // On document save
    const handleSave = () => {
        onSave()
    }

    // On document preview
    const handleDocumentPreview = () => {
        if (!onDocumentPreview) return
        onDocumentPreview()
    }

    const agencyFontConfig = () => {
        if (!agency) {
            return {}
        }
        const font_config = {
            heading_2: {
                color: agency.cover_color,
                borderColor: agency.cover_color,
            },
            heading_3: {
                color: "#ffffff",
                backgroundColor: agency.cover_color,
            },
            footer_title: {
                color: agency.cover_color,
            },
            cover_title: {
                color: agency.cover_color,
            }
        }
        return font_config
    }

    const agencyConfig = () => {
        if (!agency) {
            return {}
        }
        const config = {
            cover_color: agency.cover_color,
        }
        return config
    }

    // On pdf download
    const handlePdfDownload = () => {
        onDocumentDownload('pdf')
    }

    // On open docx download alert
    const handleOpenDocxDownloadAlert = () => {
        setShowDocxDownloadAlert(true)
    }

    // On close docx download alert
    const handleCloseDocxDownloadAlert = () => {
        setShowDocxDownloadAlert(false)
    }

    // // On docx download
    const handleDocxDownload = () => {
        onDocumentDownload('docx')
        setShowDocxDownloadAlert(false)
    }

    // On open sendLREModal
    // const handleOpenSendModalModal = async () => {
    //     setLoading(true)
    //     const previewData = await getPreviewData({...singleDoc, values: documentValues, checkboxValues: documentCheckboxValues})
    //     const buffer = Buffer.from(previewData, 'base64')
    //     console.log('setSendModalDefaultAttachments', {...singleDoc, base64: previewData.split(',')[1], size: buffer.byteLength })
    //     setSendModalDefaultAttachments([{...singleDoc, base64: previewData.split(',')[1], size: buffer.byteLength }])
    //     history.push("/registered-mail/tracking");
    //     setShowRegisteredMailSendModal(true)
    //     setLoading(false)
    // }

    const handleOpenRegisteredMailModal = () => {
        createAr24MailRequestFromDocument({ document: singleDoc, documentValues, documentCheckboxValues, documentName, documentContacts: activeDocContacts })
    }

    // On open share modal
    const handleOpenShareModal = () => {
        setShowShareModal(true)
    }

    // On close share modal
    const handleCloseShareModal = () => {
        setShowShareModal(false)
    }

    // On open sign modal
    const handleOpenSignModal = (ignoreProgress) => {
        if(!ignoreProgress && progress < 1) {
            setShowDocumentNotCompletePrompt(true)
            return
        }
        setShowSignModal(true)
    }

    // On close sign modal
    const handleCloseSignModal = () => {
        setShowSignModal(false)
    }

    // On open history modal
    const handleOpenHistoryModal = () => {
        onFetchHistory()
        setShowHistoryModal(true)
    }

    // On close history modal
    const handleCloseHistoryModal = () => {
        setShowHistoryModal(false)
    }

    // On open synthesis modal
    const handleOpenSynthesisModal = async () => {
        setShowSynthesisModal(true)
    }

    // On close synthesis modal
    const handleCloseSynthesisModal = () => {
        setShowSynthesisModal(false)
    }

    // On open inject modal
    const handleOpenInjectModal = () => {
        setShowInjectModal(true)
    }

    // On close inject modal
    const handleCloseInjectModal = () => {
        setShowInjectModal(false)
    }

    // On open delete alert
    const handleOpenDeleteAlert = () => {
        setShowDeleteAlert(true)
    }

    // On close delete alert
    const handleCloseDeleteAlert = () => {
        setShowDeleteAlert(false)
    }

    // On delete document
    const handleDeleteDocument = async () => {
        setShowGlobalResponseLoader(true)
        try {
            await moveDocumentToDeleteDocuments({
                ...singleDoc,
                id: documentId,
            })
            history.push('/documents')
        } catch (err) {
        } finally {
            setShowGlobalResponseLoader(false)
        }
    }

    // On duplicate
    const handleDuplicate = async () => {
        setShowGlobalResponseLoader(true)
        try {
            const id = await duplicateDocument({
                doc: singleDoc,
                template,
            })
            history.replace(`/documents/${id}`)
            onResetSetup()
        } catch (err) {
            console.log(err)
        } finally {
            setShowGlobalResponseLoader(false)
        }
    }

    // On data download
    const handleDownloadData = () => {
        downloadDocumentData({
            documentValues,
            documentCheckboxValues,
            documentName,
            documentId,
        })
    }

    // On upload to upload
    const handleCloudUpload = async () => {
        setShowGlobalResponseLoader(true)
        try {
            await uploadToCloud({
                template,
                doc: singleDoc,
                immocloudConfig,
            })
        } catch (err) {
            console.log(err)
        } finally {
            setShowGlobalResponseLoader(false)
        }
    }

    // On more actions click away
    const handleMoreActionsClickAway = () => {
        setShowMoreActions(false)
    }

    // On toggle more actions
    const handleToggleMoreActions = () => {
        setShowMoreActions(!showMoreActions)
    }

    const handleUndo = () => {
        document.execCommand('undo', false, null)
    }

    const handleRedo = () => {
        document.execCommand('redo', false, null)
    }

    return (
        <>
            {view !== 'attachments' && <div
                className="document-main__actions"
                style={{
                    display: isMobile ? (hideActionBar ? 'none' : '') : '',
                }}
            >
                {isContentEditable && <div className="document-main__editor-toolbar mr-2">
                    <CustomTooltip content="Annuler">
                        <div className=''>
                            <button
                                className="icon-btn icon-btn--sm"
                                onClick={handleUndo}
                            >
                                <Undo fontSize='small' />
                            </button>
                        </div>
                    </CustomTooltip>
                    <CustomTooltip content="Rétablir">
                        <button
                            className="icon-btn icon-btn--sm"
                            onClick={handleRedo}
                        >
                            <Redo fontSize='small' />
                        </button>
                    </CustomTooltip>
                </div>}
                <button
                    className="button height-40 button--outline-primary button--with-icon btn-text button--hover-light-pink mr-2"
                    onClick={() => setShowRenderSettingsModal(true)}
                >
                    <PaletteOutlined fontSize="small" /><span className=''> Mise en page</span>
                </button>
                {isFeatureEnabled(FEATURE.EDITABLE_CONTENT_LITE, siteConfigs, partner, user) && <button
                    className={`button rounded-sm btn-text button--with-icon ${isContentEditable ? 'button--outline-success' : 'button--outline-danger'}`}
                    onClick={onToggleContentEditable}
                >
                    <EditNote /><span className=''>{isContentEditable ? 'Edition activée' : 'Edition désactivée'}</span>
                </button>}
                <div className='flex ml-auto items-center right-buttons flex-wrap'>
                    <button
                        className="button button--with-icon button--outline-primary-light rounded-sm btn-text"
                        onClick={handleDocumentPreview}
                    >
                        <Eye /> <span className=''>Aperçu</span>
                    </button>
                    <button
                        className="button height-40 button--with-icon btn-text"
                        onClick={handleSave}
                    >
                        <CheckCircle fontSize="small" /> <span className='btn-text'>Sauvegarder</span>
                    </button>
                    <ClickAwayListener onClickAway={handleMoreActionsClickAway}>
                        <div className="document-main__actions_more">
                            <button
                                className="button action-btn small-icon"
                                onClick={handleToggleMoreActions}
                            >
                                <span>
                                    <MoreVert />
                                    Action
                                </span>
                            </button>
                            {showMoreActions && (
                                <div className="more-dropdown">
                                    <ul>
                                        <li className="">
                                            <button className="btn-hover">
                                                <span className="icon"><Download /></span>
                                                <span className="text">
                                                    Télécharger
                                                </span>
                                                <div className="icon-btn__popover">
                                                    <button onClick={handlePdfDownload}>pdf</button>
                                                    <button onClick={handleOpenDocxDownloadAlert}>
                                                        docx
                                                    </button>
                                                </div>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="">
                                            {immocloudUploadAvailable() &&
                                                <button onClick={handleCloudUpload}>
                                                    <span className="icon"><CloudUpload /></span> {
                                                        "Envoyer vers Immocloud"
                                                        // immocloudConfig.handle === 'customer_id'
                                                        //     ? "Envoyer vers l'Immocloud du contact"
                                                        //     : "Envoyer vers l'Immocloud du bien"
                                                    }
                                                </button>
                                            }
                                        </li>
                                        <li className="">
                                            <button onClick={handleOpenShareModal}>
                                                <span className="icon"><Share /></span>
                                                <span className="text">
                                                    Partager
                                                </span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={
                                                    handleOpenSynthesisModal
                                                }
                                            >
                                                <span className="icon"><FileAlt /></span>{' '}
                                                <span className="text">
                                                    Synthèse
                                                </span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={handleOpenHistoryModal}
                                            >
                                                <span className="icon"><History /></span>{' '}
                                                <span className="text">
                                                    Historique
                                                </span>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <button

                                                onClick={() => handleOpenSignModal()}
                                            >
                                                <span className="icon"><Draw /></span> Signer
                                            </button>
                                        </li>
                                        <li>
                                            {isFeatureEnabled(FEATURE.AR24, siteConfigs) &&
                                                <button onClick={handleOpenRegisteredMailModal}>
                                                    <span className="icon"><RegisteredMail /></span>
                                                    Envoyer par LRE
                                                </button>
                                            }
                                        </li>
                                    </ul>
                                    <ul>

                                        <li>
                                            <button
                                                onClick={handleDownloadData}
                                            >
                                                <span className="icon"><FileDownload /></span>{' '}
                                                <span className="text">
                                                    Télécharger les données
                                                </span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={handleOpenInjectModal}
                                            >
                                                <span className="icon"><Loop /></span>{' '}
                                                <span className="text">
                                                    Injecter
                                                </span>
                                            </button>
                                        </li>
                                    </ul>

                                    <ul>
                                        {isFeatureEnabled(FEATURE.STANDARD_TEMPLATES, siteConfigs) && <li>
                                            <button
                                                onClick={onCreateStandardTemplate}
                                            >
                                                <span className="icon"><Fave /></span>{' '}
                                                <span className="text">
                                                    Créer un modèle type
                                                </span>
                                            </button>
                                        </li>}

                                        <li className='duplicate'>
                                            <button onClick={handleDuplicate}>
                                                <span className="icon"><Duplicate /></span>{' '}
                                                <span className="text">
                                                    Dupliquer
                                                </span>
                                            </button>
                                        </li>
                                        <li className='trash'>
                                            <button
                                                onClick={handleOpenDeleteAlert}
                                            >
                                                <span className="icon"><Delete /></span>{' '}
                                                <span className="text">
                                                    Supprimer
                                                </span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>}
            {view === 'attachments' && <div
                className="document-main__actions"
                style={{
                    display: isMobile ? (hideActionBar ? 'none' : '') : '',
                }}
            >
                <div className='flex ml-auto items-center right-buttons flex-wrap'>
                    <button
                        // className='btn btn--with-icon btn--small btn--light btn--border-primary btn--border-radius-sm'
                        className='button button--with-icon button--outline-primary-light rounded-sm btn-text'

                        onClick={() => {
                            if(!isSidePanelOpen)
                                onToggleSidePanel()
                                setPrefilledAttachmentVariable(null)
                            
                        }}>
                            <AddCircleOutline fontSize='small' />
                            Ajouter des pièces jointes
                        </button>
                    <button
                        className="button height-40 button--with-icon btn-text"
                        onClick={handleSave}
                    >
                        <Save fontSize="small" /> <span className='btn-text'>Sauvegarder</span>
                    </button>

                </div>
            </div>}

            <div className={`document-main__body ${view === 'attachments' ? 'document-main__body--attachments' : ''}`}>
                {view === 'variables' && (
                    <SingleDocumentVariables
                        templateData={templateData}
                        documentValues={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        onCustomCoverChange={onCustomCoverChange}
                        useCustomCover={useCustomCover}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        isContentEditable={isContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        templateMetaSections={templateMetaSections}
                        linesAdded={linesAdded}
                        attachments={attachments}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        defaultAttachments={defaultAttachmentsForDocument(defaultAttachments, templateData, documentValues)}
                        docId={documentId}
                    />
                )}
                {view === 'attachments' && (
                    <SingleDocumentAttachments
                        attachments={attachments}
                        docId={documentId}
                        singleDoc={{...singleDoc, values: documentValues}}
                        template={templateData}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        onSetView={onSetView}
                        onSetTab={onSetTab}
                        onToggleSidePanel={onToggleSidePanel}
                        isSidePanelOpen={isSidePanelOpen}
                    />
                )}
            </div>

            {showShareModal && (
                <SingleDocumentShare
                    onClose={handleCloseShareModal}
                    attachments={attachments}
                    onAttachmentShareUpdate={onAttachmentsShareUpdate}
                    onAttachmentsShareSave={onAttachmentsShareSave}
                    documentName={documentName}
                    documentId={documentId}
                    singleDoc={singleDoc}
                    template={template}
                    documentValues={documentValues}
                    useCustomCover={useCustomCover}
                />
            )}

            {showSignModal && (
                <SingleDocumentSignVialink
                    onClose={handleCloseSignModal}
                    documentName={documentName}
                    docId={documentId}
                    docAttachments={attachments}
                    docSignatureRecipients={docSignatureRecipients}
                    singleDoc={{
                        ...singleDoc,
                        name: documentName,
                        values: documentValues,
                        custom_cover: useCustomCover,
                        id: documentId,
                    }}
                    docContacts={activeDocContacts}
                    template={template}
                    renderOptions={renderOptions}
                />
            )}


            {showRenderSettingsModal && (
                <SingleDocumentRenderSettings
                    onClose={() => setShowRenderSettingsModal(false)}
                    previewSettings={previewSettings}
                    onPreviewRefresh={onPreviewRefresh}
                    linesAdded={linesAdded}
                    useCustomCover={useCustomCover}
                    templateData={templateData}
                    onSave={onRenderSettingsSave}
                />
            )}

            {showHistoryModal && (
                <SingleDocumentHistory
                    onClose={handleCloseHistoryModal}
                    docHistory={docHistory}
                    docEvents={docEvents}
                    onRestore={onVersionRestore}
                />
            )}

            {showSynthesisModal && (
                <SingleDocumentSynthesis
                    onClose={handleCloseSynthesisModal}
                    documentValues={documentValues}
                    onSetDocumentValues={onSetDocumentValues}
                    data={template}
                />
            )}

            {showInjectModal && (
                <SingleDocumentInject
                    onClose={handleCloseInjectModal}
                    template={template}
                    doc={{
                        ...singleDoc,
                        name: documentName,
                        values: documentValues,
                        custom_cover: useCustomCover,
                        id: documentId,
                    }}
                    documentValues={documentValues}
                />
            )}

            {showDeleteAlert && (
                <Alert
                    onClose={handleCloseDeleteAlert}
                    text="Êtes-vous sûr de vouloir supprimer ce document ?"
                    onSubmit={handleDeleteDocument}
                    deleteAlert={true}
                />
            )}

            {showDocxDownloadAlert && (
                <Alert
                    onClose={handleCloseDocxDownloadAlert}
                    onOkClose={handleDocxDownload}
                    text="Note importante"
                    bodyText="Immo Docs ne pourra être tenu pour responsable des modifications apportées au .docx"
                    showOk
                    okBtnText="Télécharger le .docx"
                />
            )}
            {showDocumentNotCompletePrompt && (
                <Alert
                    onClose={() => setShowDocumentNotCompletePrompt(false)}
                    text="Document non complet"
                    bodyText="Le document n'est pas complet. Veuillez le compléter avant de le signer."
                    showOk
                    showCancel
                    okBtnText='Retour au document'
                    cancelBtnText="Continuer à signer"
                    onCancel={() => {
                        setShowDocumentNotCompletePrompt(false)
                        handleOpenSignModal(true)}
                    }
                    onOkClose={() => {
                        setShowDocumentNotCompletePrompt(false)
                        onNavigateToEmptyVariable()
                    }}
                />
            )}
        </>
    )
}

export default SingleDocumentMain
