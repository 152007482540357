import { useContext, useRef } from 'react'

import { Plus, Times, Delete, Download, Upload } from '../../assets/icons'
import { NotificationContext } from '../../context'

const FileUploadDnD = ({
  btnText = 'Ajouter',
  label,
  multiple = false,
  accept = '',
  onChange = () => { console.error("FileUploadDnd onChange not implemented!") },
  name = '',
  imagePreview,
  onImageRemove,
  note,
  title,
  description,
  minimumImageDimensions,
  disabled,
  buttonPosition = "bottom",
  className,
  height = "default",
  maxSize,
  noButtons = false,
  dropDisabled = false,
  previewStyle = {},
}) => {
  const fileRef = useRef()
  const { setNotification } = useContext(NotificationContext)

  const validateImageDimensions = (file) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        const { width, height } = img
        if (width < minimumImageDimensions.width || height < minimumImageDimensions.height) {
          setNotification({ msg: `Le fichier ${file.name} ne respecte pas les dimensions requises`, type: 'warning' })
          resolve(null)
        } else {
          resolve(file)
        }
      }
    })
  }

  // On change
  const handleChange = async (e) => {
    let filteredFiles = []
    if (maxSize) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > maxSize) {
          setNotification({ msg: `Le fichier ${e.target.files[i].name} dépasse la taille maximale autorisée`, type: 'warning' })
        } else {
          filteredFiles.push(e.target.files[i])
        }
      }
    } else {
      filteredFiles = e.target.files
    }
    if (minimumImageDimensions) {
      const promises = []
      for (let i = 0; i < filteredFiles.length; i++) {
        promises.push(validateImageDimensions(filteredFiles[i]))
      }
      filteredFiles = await Promise.all(promises)
      filteredFiles = filteredFiles.filter(f => Boolean(f))
    }
    if(filteredFiles.length === 0) {
      setNotification({ msg: 'Aucun fichier ne correspond aux règles spécifiées', type: 'warning' })
    }
    onChange(filteredFiles, name)
    e.target.value = null
  }

  // On button click
  const handleOpenFileWindow = () => {
    if (!fileRef.current) return
    fileRef.current.click()
  }

  // On drag enter
  const handleDragEnter = (e) => {
    e.preventDefault()
  }

  // On drag leave
  const handleDragLeave = (e) => {
    e.preventDefault()
  }

  // On drag over
  const handleDragOver = (e) => {
    e.preventDefault()
  }

  // On drop
  const handleDrop = (e) => {
    onChange(e.dataTransfer.files, name)
    e.preventDefault()
  }

  // On image remove
  const handleImageRemove = () => {
    onImageRemove(name)
  }

  return (
    <div className={`file-upload-dnd ${disabled ? "disabled" : ""}`}>
      <input type="file" onChange={handleChange} multiple={multiple} accept={accept} ref={fileRef} disabled={disabled} />
      <div
        className={`${!dropDisabled ? "file-upload-dnd__box" : ""} flex ${buttonPosition === "inside-right" ? "flex-row items-center" : ""} ${className || ""} ${height !== 'default' ? `height-${height}` : ''}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!imagePreview && !dropDisabled ? (
          <>
            <p className="flex items-center"><Upload /> {label || `ou glisser-déposer une pièce jointe`}</p>
          </>
        ) : (
          <div className="image-preview" style={previewStyle}>
            <img src={imagePreview} style={previewStyle} alt="" />
          </div>
        )}
        {!noButtons && buttonPosition === "inside-right" && <div className='file-upload-dnd__buttons'>
          <button className="button" type="button" onClick={handleOpenFileWindow}>
            {btnText}
          </button>
        </div>}
      </div>
      <div className='file-upload-dnd__info'>
        {title && <div className='file-upload-dnd__title'>{title}</div>}
        {note && <div className='file-upload-dnd__note'>{note}</div>}
        {description && <div className='file-upload-dnd__description'>{description}</div>}
      </div>

      {!noButtons && buttonPosition === "bottom" && <div className='file-upload-dnd__buttons'>
        <button class="button rounded-sm button--with-icon" type="button" onClick={handleOpenFileWindow}>
          <Upload />
          {btnText}
        </button>
        {imagePreview && <button class="button button--with-icon rounded-sm button--outline-danger" type="button" onClick={handleImageRemove}>
          <Delete />
          Supprimer
        </button>}
      </div>}

    </div>
  )
}

export default FileUploadDnD