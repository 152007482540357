import axios from 'axios'
import { urlSuffixForEnvironment } from '../utils'
import firebase from './firebase'
import config from '../config.json'
import { FUNCTIONS_BASE_URL, FUNCTIONS_BASE_URL_LOCAL } from '../constants'

const baseUrl = `${config.useLocalApi ? FUNCTIONS_BASE_URL_LOCAL : FUNCTIONS_BASE_URL}/api${urlSuffixForEnvironment(config.environment)}`

const log_event = async (events, partner) => {
  if(!events) {
    return
  }  
  return await post_request(`${baseUrl}/log_event`, { events, partner })
}

const post_request = async (url, data) => {
  const token = await firebase.auth().currentUser.getIdToken()
  let response = await axios.post(`${url}?id_token=${token}`, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  if(response.data.error) {
    console.log("firestore error")
    console.log(response.data.error)
    return response.data
  } 
  return response.data
}

export {
  log_event
}