import { useState, Children, cloneElement, useEffect } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import { ArrowDropDown } from '../../assets/icons'

const Select = ({ children, label, onChange, selected = null, placeholder, className, type = 'default', caretPosition = 'end' }) => {
  const [currentLabel, setCurrentLabel] = useState('')
  const [currentValue, setCurrentValue] = useState('')
  const [currentSet, setCurrentSet] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)

  // On item click
  const handleItemClick = (val, text, defaultAction, preventHide) => { 
    if(defaultAction) {
      defaultAction()
    } else {
      onChange(val)
      setCurrentLabel(text)
      setCurrentValue(val)
    }

    if(!preventHide) {
      setShowDropdown(false)
    }
  }

  // Items
  const items = Children.map(children, (child) => {
    if(!child) return null
    if(child.type !== Option && child.type !== OptionGroupHeader) {
      console.warn('Invalid child element, please use Option or OptionGroupHeader')
    }
    if(child.type !== OptionGroupHeader) {
      const props = {
        onClick: () => handleItemClick(child.props.value, child.props.children, child.props.defaultAction, child.props.preventHide),
        type: type,
        selected: selected === child.props.value
      }
      return cloneElement(child, props)
    }
    return child
  }).filter(Boolean)

  // Set current label and value 
  // useEffect(() => {
  //   if(currentSet || !items || items.length === 0) return 
    
  //   if(selected) {
  //     const findItem = items.find(i => i.props.value === selected)
  //     if(findItem) {
  //       setCurrentLabel(findItem.props.children || placeholder)
  //       setCurrentValue(findItem.props.value)
  //     }else {
  //       setCurrentLabel(items[0].props.children || placeholder)
  //       setCurrentValue(items[0].props.value)
  //     }
  //   }else {
  //     setCurrentLabel(items[0].props.children || placeholder)
  //     setCurrentValue(items[0].props.value)
  //   }
  //   setCurrentSet(true)
  // }, [selected, items, currentSet, placeholder])

  // Set current label and value 
  useEffect(() => {
    if(selected !== undefined || selected !== null) {
      setCurrentValue(selected)
      const findItem = items.find(i => i.props.value === selected)
      if(findItem) {
        if(findItem.props.label) {
          setCurrentLabel(findItem.props.label)
        } else {
          setCurrentLabel(findItem.props.children)
        }
      } else if(placeholder) {
        setCurrentLabel(placeholder)
      } else {
        if(items[0].props.label) {
          setCurrentLabel(items[0].props.label)
        } else {
          setCurrentLabel(items[0].props.children)
        }
      }
    }
  }, [selected, items])

  // On click away
  const handleClickAway = () => {
    if(showDropdown) setShowDropdown(false)
  }

  // On toggle dropdown
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <div className={`select-v2 ${className ? className : ''} select-v2--${type} select-v2--caret-${caretPosition}`}>
      {label && <div className="select-v2__label">{label}: </div>}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`select-v2__body ${showDropdown ? 'opened' : ''} select-v2--${type}__body`}>
          {type === 'default' && (
            <div className="select-v2__body_selected" onClick={handleToggleDropdown}>
              <p>{currentLabel? currentLabel : 'Choisir'}</p>
              <ArrowDropDown />
            </div>
          )}
          {type === 'filled' && (
            <div className="select-v2__body_selected select-v2__body_selected--filled" onClick={handleToggleDropdown}>
              <p>{currentLabel? currentLabel : 'Choisir'}</p>
              <ArrowDropDown />
            </div>
          )}
          {type === 'colorpicker' && (
            <div className="select-v2__body_selected select-v2__body_selected--colorpicker" onClick={handleToggleDropdown}>
              <div className="color-box" style={{backgroundColor: `#${currentValue}`}}></div>
              <p className="color-text">{currentLabel? currentLabel : 'Choisir'}</p>
            </div>
          )}
          {type === 'status' && (
            <div className="select-v2__body_selected select-v2__body_selected--status" onClick={handleToggleDropdown}>
              <p className="selected-status"><span className={`${currentValue}`}></span>{currentLabel? currentLabel : 'Choisir'}</p>
            </div>
          )}
          {showDropdown && (
            <div className={`select-v2__body_dropdown select-v2__body_dropdown--${type} u-custom-scrollbar--3`}>
              {items}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export const Option = ({ children, selected, ...props }) => {
  return (
    <div className={`dropdown-item ${children === '' ? 'dropdown-item--empty' : ''} ${selected ? 'selected' : ''}`} {...props}>
      {props.type === 'default' && (
        <p>{children}</p>
      )}
      {props.type === 'colorpicker' && (
        <p><span className="color-box" style={{backgroundColor: `#${props.value}`}}></span>{children}</p>
      )}
      {props.type === 'status' && (
        <p><span className={`status-box ${props.value}`}></span>{children}</p>
      )}
      {props.type === 'filled' && (
        <p>{children}</p>
      )}
    </div>
  )
}

export const OptionGroupHeader = ({ children }) => {
  return (
    <div className="select-v2__group-header">
      {children}
    </div>
  )
}

export default Select 