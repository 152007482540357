import React, { createContext, useContext, useEffect, useState } from 'react'
import { areSectionConditionsMet, arrayWithOrderedIds, defaultAttachmentsForDocument } from '../../utils'
import { AttachmentsContext } from '../attachments/attachmentsState'
import byteConverter from '../../helpers/byteConverter'

export const SingleDocumentContext = createContext()

const SingleDocumentState = ({ children }) => {

  const { defaultAttachments, defaultAttachmentsOrder } = useContext(AttachmentsContext)

  const [templateObjectWithUniqueVarIndexes, setTemplateObjectWithUniqueVarIndexes] = useState(null)
  const [documentValues, setDocumentValues] = useState({})
  const [documentAttachments, setDocumentAttachments] = useState([])

  const [attachmentVariables, setAttachmentVariables] = useState([])
  const [filteredAttachmentVariables, setFilteredAttachmentVariables] = useState([])

  const [filteredDefaultAttachments, setFilteredDefaultAttachments] = useState([])

  const [filteredDocumentAttachments, setFilteredDocumentAttachments] = useState([]) // filter document attachments to exclude inactive attachment variables

  useEffect(() => {
    if (!templateObjectWithUniqueVarIndexes) {
        return
    }
    const extractAttachmentVariables = (sections) => {
      const attachmentVars = []
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        const isSectionAvailable = areSectionConditionsMet(section, documentValues)
        if (section.data_type === 'attachment') {
          attachmentVars.push({...section, available: isSectionAvailable})
        }
      }
      return attachmentVars
    }
    const attachmentVars = extractAttachmentVariables(templateObjectWithUniqueVarIndexes.sections)
    const filteredAttachmentVars = attachmentVars.filter(v => v.available)
    const uniqueAttachmentVars = attachmentVars.filter((v, i, a) => a.findIndex(t => (t.variable === v.variable)) === i)
    const uniqueFilteredAttachmentVars = filteredAttachmentVars.filter((v, i, a) => a.findIndex(t => (t.variable === v.variable)) === i)

    setAttachmentVariables([...uniqueAttachmentVars])
    setFilteredAttachmentVariables([...uniqueFilteredAttachmentVars])
  }, [templateObjectWithUniqueVarIndexes, documentValues])


  useEffect(() => {
    if (!defaultAttachments) {
      return 
    }
    let atts = Object.keys(defaultAttachments).map(key => ({
      ...defaultAttachments[key],
      id: key
    }))
    atts = defaultAttachmentsForDocument(defaultAttachments, templateObjectWithUniqueVarIndexes, documentValues)
    setFilteredDefaultAttachments(arrayWithOrderedIds(atts, defaultAttachmentsOrder).map(att => ({
      ...att,
      name_label: att.name,
      size_label: byteConverter(att.size)
    })))
  }, [defaultAttachments, defaultAttachmentsOrder, templateObjectWithUniqueVarIndexes, documentValues])

  useEffect(() => {
    const variableAttachments = {}
    for (let doc of documentAttachments) {
      if (doc.variable) {
        // attachmentValues[doc.id] = doc.value
        if (!variableAttachments[doc.variable]) {
          variableAttachments[doc.variable] = []
        }
        variableAttachments[doc.variable].push(doc)
      }
    }
    const valuesUpdates = {}
    for(let key in variableAttachments) {
      valuesUpdates[key] = variableAttachments[key].map(att => `${att.name}.${att.format}`).join(', ')
    }
    setDocumentValues((prev) => ({...prev, ...valuesUpdates}))
  }, [documentAttachments, setDocumentValues])

  useEffect(() => {
    setFilteredDocumentAttachments(documentAttachments.filter(att => {
      if(!att.variable) return true
      return filteredAttachmentVariables.find(v => v.variable === att.variable)
    }))
  }, [filteredAttachmentVariables, documentAttachments])

  return <SingleDocumentContext.Provider value={{
    templateObjectWithUniqueVarIndexes,
    setTemplateObjectWithUniqueVarIndexes,
    documentValues,
    setDocumentValues,
    filteredDefaultAttachments,
    attachmentVariables,
    filteredAttachmentVariables,
    documentAttachments,
    setDocumentAttachments,
    filteredDocumentAttachments,
  }}>
    {children}
  </SingleDocumentContext.Provider>
}

export default SingleDocumentState
