import { Modal } from '../../ui_new'
import { useContext, useEffect, useState, useRef } from "react";
import { RegisteredMailContext } from "../../../context";
import MailModalContentSender from './MailModalContentSender';
import MailModalMenu from './MailModalMenu';
import MailModalContentRecipients from './MailModalContentRecipients';
import MailModalContentDocuments from './MailModalContentDocuments';
import MailModalContentMessage from './MailModalContentMessage';
import MailModalContentSummary from './MailModalContentSummary';
import MailModalContentConfirmation from './MailModalContentConfirmation';
import { Plus, Associate, CheckCircle } from "../../../assets/icons";
import { ArrowBack } from '@mui/icons-material';

const MailModal = ({ onClose, data, loadingMessage, errorMessage, onClearError, responseStatus, onClearStatus }) => {
  const step2Ref = useRef();
  const step3Ref = useRef();
  const step4Ref = useRef();

  const { sendAr24Mail, senderModal, showSenderModal, ar24Sender, resendSenderConfirmation } = useContext(RegisteredMailContext)
  const senderStatus = ar24Sender?.status || 'none'

  const [step, setStep] = useState('sender')
  const [recipients, setRecipients] = useState([{
    firstName: '',
    lastName: '',
    email: '',
    type: 'particulier',
    consent: false,
  }])
  const [documents, setDocuments] = useState([])
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (data) {
      if (data.recipients) {
        setRecipients(data.recipients)
      }
      if (data.documents) {
        setDocuments(data.documents)
      }
    }
  }, [data])

  const onSummarySubmit = async () => {
    await sendAr24Mail({ recipients, documents, message, documentName: data.documentName, documentId: data.documentId, templateId: data.templateId, source: data.source, documentValues: data.documentValues, documentContentChanges: data.documentContentChanges })
  }

  const onSubmitMessage = () => {
    // setMessage(message)
    setStep('summary')
  }

  const displayContent = () => {
    return !loadingMessage && !errorMessage && !responseStatus
  }

  const goBack = () => {
    if (step === 'recipients') {
      setStep('sender')
    }
    if (step === 'documents') {
      setStep('recipients')
    }
    if (step === 'message') {
      step4Ref.current?.handleReturn()
    }
    if (step === 'summary') {
      setStep('message')
    }
  }

  ///////////////////
  // step #1 actions
  ///////////////////
  const handleCreateSender = () => {
    showSenderModal('create')
  }

  const handleAsociateSender = () => {
    showSenderModal('associate')
  }

  const handleResendConfirmation = async () => {
    await resendSenderConfirmation()
  }

  const handleOpenSender = () => {
    showSenderModal('modify')
  }

  ///////////////////
  // step #3 actions
  ///////////////////
  const attachmentTotalMaxSize = 2.56e+8

  return (
    <Modal onClose={onClose} className={`modal--padding-sm registered-mail-modal ${(responseStatus === 'success' || errorMessage) ? 'modal-v2--mid-large' : ''}`}>
      {loadingMessage &&
        <div className="response-loader-v2__content">
          <p>{loadingMessage}</p>
          <div className="loader-v2 mt-20"></div>
        </div>
      }
      {errorMessage && <div className="folder-modal-v2">
        <div className='folder-modal-v2__heading'>
          <button
            className='btn btn--medium btn--transparent btn--border-primary'
            onClick={onClearError}
          >
            Retour
          </button>
        </div>
        <div className='folder-modal-v2__content'>
          {/* <h2 className='modal-title'>Modifier le compte expéditeur</h2> */}
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p className='text-error' >{errorMessage}</p>
            </li>
          </ul>
        </div>
      </div>
      }
      {responseStatus === 'success' &&
        <MailModalContentConfirmation onClose={onClose} data={data} />
      }
      {displayContent() && <>
        <div className='folder-modal-v2__heading'>
          <div className='heading-with-return-btn'>
            {step !== 'sender' && < button className='button button--transparent return pl-0' onClick={goBack}>
              <ArrowBack fontSize={'small'} />
              Retour
            </button>}
            <div className='signature-heading--title'>
              <h1 className='modal-title'>Envoyer par lettre recommandée électronique</h1>
              <h2>{data.documentName}</h2>
            </div>
          </div>
          <div className="folder-modal-v2__form_actions">
            {/* step #1 sender */}
            {displayContent() && step === 'sender' &&
              <div className="buttons-row">
                <button
                  className='btn btn--medium btn--transparent'
                  onClick={onClose}
                >
                  Annuler
                </button>

                {senderStatus === 'none' && <>
                  <button
                    className='button btn--large button--outline-primary'
                    onClick={handleAsociateSender}
                  >
                    <Associate />
                    Associer un compte AR24
                  </button>
                  <button
                    className='btn btn--large btn--primary btn--with-icon mr-auto'
                    onClick={handleCreateSender}
                  >
                    <Plus />
                    Créer un compte AR24
                  </button>
                </>}
                {(senderStatus === 'confirmation-pending' || senderStatus === 'invitation-pending') && <button
                  className='btn btn--large btn--primary'
                  onClick={handleResendConfirmation}
                >
                  Renvoyer l'email de confirmation
                </button>}
                {(senderStatus === 'api-access-pending' || senderStatus === 'verification-pending' || senderStatus === 'non-verified') && <button
                  className='button action-btn bg-pink'
                  onClick={handleOpenSender}
                >
                  <span>Accéder au compte</span>
                </button>}
                {senderStatus === 'verified' && <>
                  <div className='btn-separator'></div>
                  <button
                    disabled={senderStatus !== 'active' && senderStatus !== 'verified'}
                    className='btn btn--large btn--primary'
                    onClick={() => setStep('recipients')}
                  >
                    Suivant
                  </button>
                </>}
              </div>
            }
            {/* step #2 recipients */}
            {displayContent() && step === 'recipients' &&
              <div className="buttons-row">
                <button
                  className='btn btn--medium btn--transparent'
                  onClick={onClose}
                >
                  Annuler
                </button>
                <div className='btn-separator'></div>
                <button
                  disabled={recipients.length === 0}
                  className='btn btn--large btn--primary'
                  onClick={() => step2Ref.current?.handleContinue()}
                >
                  Suivant
                </button>
              </div>
            }
            {/* step #3 documents */}
            {displayContent() && step === 'documents' &&
              <div className="buttons-row">
                <button
                  className='btn btn--medium btn--transparent'
                  onClick={onClose}
                >
                  Annuler
                </button>
                <div className='btn-separator'></div>
                <button
                  className='btn btn--large btn--primary'
                  onClick={() => step3Ref.current?.handleSubmit()}
                  disabled={step3Ref.current?.calculateSize() > attachmentTotalMaxSize}
                >
                  Suivant
                </button>
              </div>
            }
            {/* step #4 message */}
            {displayContent() && step === 'message' &&
              <div className="buttons-row">
                <button
                  className='btn btn--medium btn--transparent'
                  onClick={onClose}
                >
                  Annuler
                </button>
                <div className='btn-separator'></div>
                <button
                  className='btn btn--large'
                  onClick={() => step4Ref.current?.handleContinue()}
                >
                  Suivant
                </button>
              </div>
            }

            {/* step #5 summary */}
            {displayContent() && step === 'summary' &&
              <div className="buttons-row">
                <button
                  className='btn btn--medium btn--transparent'
                  onClick={onClose}
                >
                  Annuler
                </button>
                <div className='btn-separator'></div>
                <button
                  className='button action-btn bg-pink'
                  onClick={onSummarySubmit}
                >
                  <span>
                    <CheckCircle />
                    Envoyer
                  </span>
                </button>
              </div>
            }
          </div>


        </div>

      </>}

      {displayContent() && <div className='registered-mail-send-modal'>
        {step !== 'confirmation' && <div className='registered-mail-send-modal-sidebar-menu'>
          <MailModalMenu currentItem={step} />
        </div>}
        <div className='registered-mail-send-modal-sidebar-content'>

          {step === 'sender' &&
            <MailModalContentSender
              onClose={onClose}
              onContinue={() => setStep('recipients')} />
          }
          {step === 'recipients' &&
            <MailModalContentRecipients
              ref={step2Ref}
              recipients={recipients}
              setRecipients={setRecipients}
              onReturn={() => setStep('sender')}
              onContinue={() => setStep('documents')} />
          }
          {step === 'documents' &&
            <MailModalContentDocuments
              ref={step3Ref}
              documents={documents}
              setDocuments={setDocuments}
              onReturn={() => setStep('recipients')}
              onContinue={() => setStep('message')}
              attachmentTotalMaxSize={attachmentTotalMaxSize} />
          }
          {step === 'message' &&
            <MailModalContentMessage
              ref={step4Ref}
              message={message}
              setMessage={setMessage}
              onReturn={() => setStep('documents')}
              onSubmit={onSubmitMessage} />
          }
          {step === 'summary' &&
            <MailModalContentSummary
              recipients={recipients}
              documents={documents}
              message={message}
              onReturn={() => setStep('message')}
              onSubmit={onSummarySubmit} />
          }
        </div>
      </div>}

    </Modal >
  )
}

export default MailModal