import React from 'react'
import { Check } from "@material-ui/icons";
import { CheckCircle } from '../../../assets/icons'

const MailModalContentSummary = ({ onReturn, onSubmit, recipients }) => {

  const labelForRecipientType = (type) => {
    switch (type) {
      case 'particulier':
        return 'Particulier'
      case 'professionnel':
        return 'Professionnel'
      default:
        return 'Non renseigné'
    }
  }

  const defaultPricePerRecipient = 3.49 // HT

  const totalPrice = (recipients?.length || 0) * defaultPricePerRecipient

  return (
    <div className='registered-mail-send-modal-envoi-container'>
      <div className='flex flex-row modal-section-title mb-5'>
        Vérifiez les informations avant envoi
      </div>
      <div className="mb-5">
        <p>Chaque destinataire renseigné ci-dessous va recevoir une lettre recommandée électronique. <br />Vérifiez bien les informations avant envoi.</p>
      </div>
      <div className="table-responsive overflow-x-auto">
        <table className='registered-mail-send-modal-list-table mb-2'>
          <thead>
            <tr>
              <th>Prénom</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Statut</th>
              <th>Prix (€ HT)</th>
            </tr>
          </thead>
          <tbody>
            {recipients?.map((recipient, index) => (
              <tr key={index}>
                <td>
                  <label className="field-label">Prénom</label>
                  {recipient.firstName}
                </td>
                <td>
                  <label className="field-label">Nom</label>
                  {recipient.lastName}
                </td>
                <td>
                  <label className="field-label">Email</label>
                  {recipient.email}
                </td>
                <td>
                  <label className="field-label">Statut</label>
                  {labelForRecipientType(recipient.type)}
                </td>
                <td>
                  <label className="field-label">Prix (€ HT)</label>
                  {defaultPricePerRecipient}€
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row justify-end align-center mb-40">
        <p className='total-price'>Coût total : <b>{totalPrice}€ HT</b></p>
      </div>
    </div>
  )
}

export default MailModalContentSummary